let onboardingUrl = '';
let centralUrl = '';
let playerConfigUrl = '';
let mediaMetaDataUrl = '';
let telemetryUrl = '';
let webPlayerEnvQueryParam = '';
let webplayerEnvAttribute = '';
let webplayerStudioParam = '';
let controlUrl = '';
let localStoragePrefix = '';
let dataUsageUrl = '';
let sitesClientUrl = '';
let streamUsageUrl = '';
let studioUrl = '';
let mediaStatusUrl = '';
let statusUrl = '';
let sysEventUrl = '';
let cuesUrl = '';
let logsGatewayUrl = '';
let librarySearchUrl = '';
let marathonUrl = '';
let hotspotsUrl = '';

switch (true) {
  case /[-_a-zA-Z0-9]*.dev-studio.pages.dev/.test(window.location.host):
  case /int-studio.resi.io/.test(window.location.host):
  case /localhost:3000/.test(window.location.host):
  case /127.0.0.1:3000/.test(window.location.host):
    onboardingUrl = 'https://int-onboarding.resi.io';
    centralUrl = 'https://int-central.resi.io';
    playerConfigUrl = 'https://int-player-conf.resi.io';
    mediaMetaDataUrl = 'https://int-media-metadata.resi.io';
    telemetryUrl = 'https://int-telemetry.resi.io';
    controlUrl = 'https://int-control.resi.io';
    dataUsageUrl = 'https://int-data-usage.resi.io';
    webPlayerEnvQueryParam = '&env=int';
    webplayerEnvAttribute = 'int';
    webplayerStudioParam = window.location.host === 'int-studio.resi.io' ? 'studio-int' : 'studio-local';
    localStoragePrefix = 'studio-int';
    sitesClientUrl = 'https://int-sites.resi.io/';
    streamUsageUrl = 'https://int-stream-usage.resi.io';
    studioUrl = 'https://int-studio.resi.io';
    mediaStatusUrl = 'https://int-media-status.resi.io';
    statusUrl = 'https://int-status.resi.io';
    sysEventUrl = 'https://int-sys-event.resi.io';
    cuesUrl = 'https://int-cues.resi.io';
    logsGatewayUrl = 'https://int-logs-gateway.resi.io';
    librarySearchUrl = 'https://int-search.resi.io';
    marathonUrl = 'https://int-marathon.resi.io';
    hotspotsUrl = 'https://int-hotspots.resi.io';
    break;
  case /studio.resi-dev.us/.test(window.location.host):
    onboardingUrl = 'https://int-onboarding.resi.io';
    centralUrl = 'https://central.resi-dev.us';
    playerConfigUrl = 'https://int-player-conf.resi.io';
    mediaMetaDataUrl = 'https://int-media-metadata.resi.io';
    telemetryUrl = 'https://int-telemetry.resi.io';
    controlUrl = 'https://int-control.resi.io';
    dataUsageUrl = 'https://int-data-usage.resi.io';
    localStoragePrefix = 'studio-int';
    webPlayerEnvQueryParam = '&env=int';
    webplayerEnvAttribute = 'int';
    webplayerStudioParam = 'studio-local';
    sitesClientUrl = 'https://sites.resi.io/';
    streamUsageUrl = 'https://int-stream-usage.resi.io';
    studioUrl = 'https://int-studio.resi-dev.us';
    mediaStatusUrl = 'https://int-media-status.resi.io';
    statusUrl = 'https://int-status.resi.io';
    sysEventUrl = 'https://int-sys-event.resi.io';
    cuesUrl = 'https://int-cues.resi.io';
    logsGatewayUrl = 'https://int-logs-gateway.resi.io';
    librarySearchUrl = 'https://int-search.resi.io';
    marathonUrl = 'https://int-marathon.resi.io';
    hotspotsUrl = 'https://int-hotspots.resi.io';
    break;
  case /prod-studio.resi.io/.test(window.location.host):
    onboardingUrl = 'https://prod-onboarding.resi.io';
    centralUrl = 'https://prod-central.resi.io';
    playerConfigUrl = 'https://player-conf.resi.io';
    mediaMetaDataUrl = 'https://media-metadata.resi.io';
    telemetryUrl = 'https://prod-telemetry.resi.io';
    controlUrl = 'https://prod-control.resi.io';
    dataUsageUrl = 'https://data-usage.resi.io';
    webplayerStudioParam = 'studio-prod';
    localStoragePrefix = 'studio-prod';
    sitesClientUrl = 'https://sites.resi.io/';
    streamUsageUrl = 'https://stream-usage.resi.io';
    studioUrl = 'https://prod-studio.resi.io';
    mediaStatusUrl = 'https://media-status.resi.io';
    statusUrl = 'https://status.resi.io';
    sysEventUrl = 'https://prod-sys-event.resi.io';
    cuesUrl = 'https://cues.resi.io';
    logsGatewayUrl = 'https://logs-gateway.resi.io';
    librarySearchUrl = 'https://search.resi.io';
    marathonUrl = 'https://marathon.resi.io';
    hotspotsUrl = 'https://hotspots.resi.io';
    break;
  default:
    onboardingUrl = 'https://onboarding.resi.io';
    centralUrl = 'https://central.resi.io';
    playerConfigUrl = 'https://player-conf.resi.io';
    mediaMetaDataUrl = 'https://media-metadata.resi.io';
    telemetryUrl = 'https://telemetry.resi.io';
    controlUrl = 'https://control.resi.io';
    dataUsageUrl = 'https://data-usage.resi.io';
    webplayerStudioParam = 'studio-prod';
    localStoragePrefix = 'studio-prod';
    sitesClientUrl = 'https://sites.resi.io/';
    streamUsageUrl = 'https://stream-usage.resi.io';
    studioUrl = 'https://studio.resi.io';
    mediaStatusUrl = 'https://media-status.resi.io';
    statusUrl = 'https://status.resi.io';
    sysEventUrl = 'https://sys-event.resi.io';
    cuesUrl = 'https://cues.resi.io';
    logsGatewayUrl = 'https://logs-gateway.resi.io';
    librarySearchUrl = 'https://search.resi.io';
    marathonUrl = 'https://marathon.resi.io';
    hotspotsUrl = 'https://hotspots.resi.io';
}

const CENTRAL_API_V1 = `${centralUrl}/internal/api/v1`;
const CENTRAL_API_V2 = `${centralUrl}/api_v2.svc`;
const CENTRAL_API_V3 = `${centralUrl}/api/v3`;
const CENTRAL_API_V4 = `${centralUrl}/api/v4`;

const DATA_USAGE_API_V1 = `${dataUsageUrl}/api/v1`;

const MEDIA_STATUS_V1 = `${mediaStatusUrl}/api/v1`;

const STATUS_V1 = `${statusUrl}/api/v1`;

const MEDIA_METADATA_API_V1 = `${mediaMetaDataUrl}/api/v1`;
const TELEMETRY_API_V1 = `${telemetryUrl}/api/v1`;
const SYS_EVENT_API_V1 = `${sysEventUrl}/api/v1`;

const CUES_API_V1 = `${cuesUrl}/api/v1`;

const ONBOARDING_API_V1 = `${onboardingUrl}/api/v1`;

const PLAYER_CONFIG_API_V1 = `${playerConfigUrl}/api/v1`;

const STREAM_USAGE_API_V1 = `${streamUsageUrl}/api/v1`;

const WEBPLAYER_ORIGIN = 'https://control.resi.io';
const WEBPLAYER_URL = `${WEBPLAYER_ORIGIN}/webplayer`;
const WEBPLAYER_SOURCE = `${WEBPLAYER_URL}/video.html`;
const WEBPLAYER_CONFIG = `${playerConfigUrl}/api/v1`;

const LOGS_GATEWAY_API_V1 = `${logsGatewayUrl}/api/v1`;

const SEARCH_API_V1 = `${librarySearchUrl}/api/v1`;

const MARATHON_API_V1 = `${marathonUrl}/internal/api/v1`;

const HOTSPOTS_API_V1 = `${hotspotsUrl}/api/v1`;

/*
  Uncomment for local linking to player repo
  See Player Repo ReadMe for Guidance
  https://github.com/resi-media/webplayer/blob/master/README.md
*/

// const WEBPLAYER_ORIGIN = 'http://localhost:5055';
// const WEBPLAYER_URL = `http://localhost:5055`;
// const WEBPLAYER_SOURCE = `${WEBPLAYER_URL}/video-dev.html`;

const WEBPLAYER_CLOUDFLARE = 'https://player-config.resi.media';

const CONTROL_WINDOW_NAME = 'control';

const STUDIO_URL = studioUrl;

export {
  ONBOARDING_API_V1,
  centralUrl,
  telemetryUrl,
  CENTRAL_API_V1,
  CENTRAL_API_V2,
  CENTRAL_API_V3,
  CENTRAL_API_V4,
  DATA_USAGE_API_V1,
  MEDIA_METADATA_API_V1,
  PLAYER_CONFIG_API_V1,
  TELEMETRY_API_V1,
  SYS_EVENT_API_V1,
  CUES_API_V1,
  localStoragePrefix,
  webPlayerEnvQueryParam,
  webplayerEnvAttribute,
  webplayerStudioParam,
  WEBPLAYER_ORIGIN,
  WEBPLAYER_SOURCE,
  WEBPLAYER_URL,
  WEBPLAYER_CLOUDFLARE,
  WEBPLAYER_CONFIG,
  controlUrl,
  CONTROL_WINDOW_NAME,
  sitesClientUrl,
  STREAM_USAGE_API_V1,
  STUDIO_URL,
  MEDIA_STATUS_V1,
  STATUS_V1,
  LOGS_GATEWAY_API_V1,
  SEARCH_API_V1,
  MARATHON_API_V1,
  HOTSPOTS_API_V1,
};
