import { root, id } from './encoders';
import * as idEndpoints from './id';
import { search } from './search';

export const encoders = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
  search,
};
