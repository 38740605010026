import * as React from 'react';
import {
  CaretLeftFilled,
  CheckCircleFilled,
  CloseCircleOutlined,
  CopyOutlined,
  DeploymentUnitOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import { autoPlacement } from '@floating-ui/react';
import {
  Inline,
  SecurityShield,
  Text,
  ToggleInput,
  Stack,
  useThemeConfig,
  Draft,
  Link,
  Hr,
  useToast,
  useForkRef,
  Broadcast,
  FieldLabel,
  useKeyDown,
} from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { HEADER_HEIGHT, HEADER_HEIGHT_SUDO_USER } from '@studio/constants/dashboard-style-constants';
import UrlPaths from '@studio/constants/url-paths';
import { useAdmin } from '@studio/contexts/admin';
import { usePrefix } from '@studio/hooks/use-prefix';
import { useTimeMode } from '@studio/hooks/use-time-mode';
import { selectCapabilities } from '@studio/store/authentication';
import { selectCustomerData, selectMatchingToggles, selectToggles } from '@studio/store/customer';
import { AdminCmdK } from '../AdminCmdK';

type _Props = object;

type _SubViews = 'capabilities' | 'customerToggles' | 'flags';

/* eslint-disable import/export */
const AdminActionMenuInternal = (_props: _Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { commonT, prefixNS } = usePrefix('navigation:');
  const { trigger } = useToast();
  const {
    currentCustomer,
    handleSwitchDefault,
    isResiAdmin,
    setDemoView,
    setView,
    showAdminView,
    showDemoView,
    showSudo,
  } = useAdmin();
  const { setMode } = useThemeConfig();
  const tabRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const customer = useSelector(selectCustomerData);
  const capabilities = useSelector(selectCapabilities) ?? [];
  const availableToggles = useSelector(selectToggles) ?? [];
  const customerToggles = (useSelector(selectMatchingToggles) ?? []).map((i) => i.name);
  const { setTimeMode } = useTimeMode();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setModal } = Draft.ModalContext.useModal();
  const ldClient = useLDClient();
  const ldFlags = ldClient?.allFlags() ?? {};
  const forkedRef = useForkRef(ref, menuRef);

  const [timeMode, setLocalTimeMode] = React.useState(localStorage.getItem('studio-time-mode') === '24hour');
  const [isHovered, setIsHovered] = React.useState(false);
  const [anchorEls, setAnchorEls] = React.useState<{
    capabilities: Element | null;
    current: _SubViews | '';
    customerToggles: Element | null;
    flags: Element | null;
  }>({
    capabilities: null,
    current: '',
    customerToggles: null,
    flags: null,
  });

  const togglePopoverView = (event: React.MouseEvent<HTMLAnchorElement>, field: _SubViews): void => {
    setAnchorEls({
      capabilities: null,
      customerToggles: null,
      flags: null,
      [field]: anchorEls[field] ? null : event.currentTarget,
      current: anchorEls[field] ? '' : field,
    });
  };

  const handleHover = React.useCallback((event: MouseEvent) => {
    setIsHovered(
      Boolean(menuRef.current?.contains(event.target as Node) || tabRef.current?.contains(event.target as Node))
    );
  }, []);

  const handleKeyPress = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
        if (event.code === 'KeyK') {
          event.preventDefault();
          setModal(
            () => (
              <AdminCmdK
                is24HrView={timeMode}
                onTimeToggle={() => {
                  setLocalTimeMode(!timeMode);
                  setTimeMode(!timeMode);
                }}
              />
            ),
            { dataTestId: 'cmd-k-modal', minHVariant: 's' }
          );
        } else if (event.code === 'KeyX') {
          setDemoView(!showDemoView);
        } else if (event.code === 'KeyV') {
          setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
        } else if (event.code === 'KeyZ') {
          setLocalTimeMode(!timeMode);
          setTimeMode(!timeMode);
        } else if (event.code === 'KeyC') {
          setView(!showAdminView);
        } else if (event.code === 'KeyA') {
          navigate(UrlPaths.ADMIN.ORGANIZATIONS);
        }
      }
    },
    [
      navigate,
      setDemoView,
      setModal,
      setMode,
      setTimeMode,
      setView,
      showAdminView,
      showDemoView,
      theme.palette.activeMode,
      timeMode,
    ]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('mouseover', handleHover);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('mouseover', handleHover);
    };
  }, [handleHover, handleKeyPress]);

  const CONTENT_SIZE = 280;

  const handleClickQuickSearch = () => {
    setModal(
      () => (
        <AdminCmdK
          is24HrView={timeMode}
          onTimeToggle={() => {
            setLocalTimeMode(!timeMode);
            setTimeMode(!timeMode);
          }}
        />
      ),
      { dataTestId: 'cmd-k-modal', minHVariant: 's' }
    );
  };

  const handleKeyDownCapabilities = useKeyDown(['Enter', ' '], (e) => togglePopoverView(e, 'capabilities'));
  const handleKeyDownOrgToggles = useKeyDown(['Enter', ' '], (e) => togglePopoverView(e, 'customerToggles'));
  const handleKeyDownFlags = useKeyDown(['Enter', ' '], (e) => togglePopoverView(e, 'flags'));
  const handleKeyDownQuickSearch = useKeyDown(['Enter', ' '], handleClickQuickSearch);

  return (
    isResiAdmin &&
    createPortal(
      <React.Fragment>
        <div
          ref={tabRef}
          css={css`
            position: fixed;
            height: auto;
            top: calc(50% - ${(showSudo ? HEADER_HEIGHT_SUDO_USER : HEADER_HEIGHT) / 2}px);
            right: 0px;
            transform: translateX(0px);
            color: white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${theme.palette.brand.resiRed};
            gap: ${theme.spacing.s};
            opacity: 0.7;
            transition:
              transform 0.2s ease-out,
              height 0.2s ease-out,
              opacity 0.2s ease-out;
            z-index: ${theme.zIndex.tooltip + 1};
            border-top-left-radius: ${theme.shape.borderRadius.l};
            border-bottom-left-radius: ${theme.shape.borderRadius.l};
            box-shadow:
              inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
              /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
              /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
              0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
              0 3.5px 6px hsla(0, 0%, 0%, 0.09);
            :focus {
              ${theme.mixins.focusOutline(theme)}
            }

            ${isHovered &&
            !showDemoView &&
            `
            transform: translateX(-${CONTENT_SIZE}px);
            right: 0px;
            height: ${menuRef.current?.clientHeight ?? 0}px;
            opacity: 0.9;
            top: calc(
              50% - ${(menuRef.current?.clientHeight ?? 0) / 2}px +
                ${(showSudo ? HEADER_HEIGHT_SUDO_USER : HEADER_HEIGHT) / 2}px
            );
            `}

            ${Boolean(Object.values(anchorEls).filter(Boolean).length) &&
            !showDemoView &&
            `
            transform: translateX(-${CONTENT_SIZE}px);
            right: 0px;
            opacity: 0.9;
            height: ${menuRef.current?.clientHeight ?? 0}px;
            top: calc(
              50% - ${(menuRef.current?.clientHeight ?? 0) / 2}px +
                ${(showSudo ? HEADER_HEIGHT_SUDO_USER : HEADER_HEIGHT) / 2}px
            );
          `}

          ${showDemoView &&
            `
            right: -100%;
          `}
          `}
          data-testid="admin-action-menu__tab"
          id="admin-action-menu__tab"
          onKeyDown={(e) => {
            if (e.code === 'Enter' || e.code === 'Space') {
              e.preventDefault();
              setIsHovered(!isHovered);
            }
            if (e.code === 'Escape') {
              setIsHovered(false);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Inline alignItems="center" gap={0} height="x28">
            <SecurityShield
              color="white"
              style={{
                flexShrink: 0,
                margin: `${theme.spacing.xl} ${theme.spacing.s}`,
                fontSize: theme.typography.pxToRem(20),
              }}
            />
          </Inline>
        </div>
        <div
          ref={forkedRef}
          css={css`
            position: fixed;
            top: calc(
              50% - ${(menuRef.current?.clientHeight ?? 0) / 2}px +
                ${(showSudo ? HEADER_HEIGHT_SUDO_USER : HEADER_HEIGHT) / 2}px
            );
            right: -1px;
            transform: translateX(${CONTENT_SIZE}px);
            color: white;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: ${theme.spacing.s};
            opacity: 0.7;
            transition: transform 0.2s ease-out;
            z-index: ${theme.zIndex.tooltip + 1};
            box-shadow:
              inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
              /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
              /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
              0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
              0 3.5px 6px hsla(0, 0%, 0%, 0.09);

            ${isHovered &&
            !showDemoView &&
            `
            transform: translateX(0px);
            right: 0px;
            opacity: 0.9;
            `}

            ${Boolean(Object.values(anchorEls).filter(Boolean).length) &&
            !showDemoView &&
            `
            transform: translateX(0px);
            right: 0px;
            opacity: 0.9;
          `}

          ${showDemoView &&
            `
            right: -100%;
          `}
          `}
          data-testid="admin-action-menu"
          id="admin-action-menu"
        >
          <Stack
            css={css`
              width: ${CONTENT_SIZE}px;
              background-color: ${theme.palette.background.paper};
            `}
            gap="m"
            p="m"
          >
            <Stack alignItems="flex-start" gap="xs" justifyContent="center" overflow="hidden" textAlign="left">
              <FieldLabel
                css={css`
                  color: ${showSudo ? theme.palette.brand.resiRed : theme.palette.text.secondary};
                `}
              >
                {prefixNS(showSudo ? 'orgOverride' : 'homeOrg')}
              </FieldLabel>
              <Text
                colorVariant="primary"
                css={css`
                  width: 100%;
                  white-space: normal;
                  word-break: break-word;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                `}
                dataTestId="admin-action-menu__sudo--customer"
                title={currentCustomer}
                truncate
                variant="body2"
                weightVariant="bold"
              >
                {currentCustomer}
              </Text>
              <Stack gap="xs">
                <Inline alignItems="center" gap="s">
                  <Link
                    as={RouterLink}
                    colorVariant="primary"
                    css={css`
                      text-decoration: none;
                      :hover {
                        text-decoration: underline;
                      }
                    `}
                    dataTestId="admin-action-menu__sub-view-plan"
                    {...(!isHovered && { tabIndex: -1 })}
                    to={UrlPaths.ADMIN.CURRENT_ORG}
                    variant="body2"
                  >
                    <Inline alignItems="center" gap="xs">
                      <CaretLeftFilled />
                      {commonT('plan_plural')}
                    </Inline>
                  </Link>

                  <Text
                    as="span"
                    colorVariant={customer?.multisitePlanName ? 'primary' : 'disabled'}
                    css={css`
                      line-height: 1;
                    `}
                    dataTestId="admin-action-menu__icon--plan"
                    variant="h4"
                  >
                    <DeploymentUnitOutlined />
                  </Text>
                  <Text
                    as="span"
                    colorVariant={customer?.webPlanName ? 'primary' : 'disabled'}
                    css={css`
                      line-height: 1;
                    `}
                    dataTestId="admin-action-menu__icon--web-plan"
                    variant="h4"
                  >
                    <Broadcast />
                  </Text>
                  <Text
                    as="span"
                    colorVariant={customer?.vodPlanName ? 'primary' : 'disabled'}
                    css={css`
                      line-height: 1;
                    `}
                    dataTestId="admin-action-menu__icon--vod"
                    variant="h4"
                  >
                    <FolderOutlined />
                  </Text>
                </Inline>
                <Inline alignItems="center">
                  <Link
                    colorVariant="primary"
                    css={css`
                      text-decoration: none;
                    `}
                    dataTestId="admin-action-menu__sub-view-org-toggles"
                    onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
                      togglePopoverView(e, 'customerToggles')
                    }
                    onKeyDown={handleKeyDownOrgToggles}
                    tabIndex={isHovered ? 0 : -1}
                    variant="body2"
                  >
                    <Inline alignItems="center" gap="xs">
                      <CaretLeftFilled />
                      {prefixNS('orgToggles')}
                    </Inline>
                  </Link>
                </Inline>
                {showSudo && (
                  <Link
                    colorVariant="negative"
                    css={css`
                      text-decoration: none;
                    `}
                    dataTestId="admin-action-menu__btn--remove-override"
                    onClick={handleSwitchDefault}
                    variant="body2"
                  >
                    <Inline alignItems="center" gap="xs">
                      <CaretLeftFilled />
                      {prefixNS('exitOverride')}
                    </Inline>
                  </Link>
                )}
              </Stack>
            </Stack>
            <Hr backgroundColor="divider" />
            <Stack gap="xs" p={0}>
              <FieldLabel colorVariant="secondary">{prefixNS('quickLinks')}</FieldLabel>
              <Link
                as={RouterLink}
                colorVariant="primary"
                css={css`
                  text-decoration: none;
                `}
                dataTestId="admin-action-menu__sub-view-org"
                to={UrlPaths.ADMIN.ORGANIZATIONS}
                {...(!isHovered && { tabIndex: -1 })}
                variant="body2"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  <div>
                    <Text as="span" colorVariant="primary" variant="body2" weightVariant="medium">
                      {prefixNS('orgSearch')}
                    </Text>
                    &#32;
                    <Text as="span" colorVariant="secondary" isItalic variant="body4" weightVariant="medium">
                      (Alt + A)
                    </Text>
                  </div>
                </Inline>
              </Link>
              <Link
                colorVariant="primary"
                dataTestId="admin-action-menu__sub-view-cmd"
                onClick={handleClickQuickSearch}
                onKeyDown={handleKeyDownQuickSearch}
                tabIndex={isHovered ? 0 : -1}
                variant="body2"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  <div>
                    <Text as="span" colorVariant="primary" variant="body2" weightVariant="medium">
                      {prefixNS('quickSearch')}
                    </Text>
                    &#32;
                    <Text as="span" colorVariant="secondary" isItalic variant="body4" weightVariant="medium">
                      (Alt + K)
                    </Text>
                  </div>
                </Inline>
              </Link>
              <Link
                colorVariant="primary"
                dataTestId="admin-action-menu__sub-view-flags"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => togglePopoverView(e, 'flags')}
                onKeyDown={handleKeyDownFlags}
                tabIndex={isHovered ? 0 : -1}
                variant="body2"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  {prefixNS('launchDarklyFlags')}
                </Inline>
              </Link>
              <Link
                colorVariant="primary"
                dataTestId="admin-action-menu__sub-view-capabilities"
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => togglePopoverView(e, 'capabilities')}
                onKeyDown={handleKeyDownCapabilities}
                tabIndex={isHovered ? 0 : -1}
                variant="body2"
              >
                <Inline alignItems="center" gap="xs">
                  <CaretLeftFilled />
                  {prefixNS('accountPermissions')}
                </Inline>
              </Link>
            </Stack>
            <Hr backgroundColor="divider" />
            <Stack gap="0">
              <ToggleInput
                checked={theme.palette.activeMode === 'dark'}
                data-testid="admin-action-menu__toggle-theme"
                disabled={!isHovered}
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="primary" variant="body2" weightVariant="medium">
                      {prefixNS('darkMode')}
                    </Text>
                    <Text colorVariant="secondary" isItalic variant="body4" weightVariant="medium">
                      (Alt + V)
                    </Text>
                  </Inline>
                }
                onChange={() => {
                  setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
                }}
              />
              <ToggleInput
                checked={timeMode}
                data-testid="admin-action-menu__toggle-time"
                disabled={!isHovered}
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="primary" variant="body2" weightVariant="medium">
                      {prefixNS('24HourView')}
                    </Text>
                    <Text colorVariant="secondary" isItalic variant="body4" weightVariant="medium">
                      (Alt + Z)
                    </Text>
                  </Inline>
                }
                onChange={() => {
                  setLocalTimeMode(!timeMode);
                  setTimeMode(!timeMode);
                }}
              />
              <ToggleInput
                checked={!showAdminView}
                data-testid="admin-action-menu__toggle-admin"
                disabled={!isHovered}
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="primary" variant="body2" weightVariant="medium">
                      {prefixNS('customerView')}
                    </Text>
                    <Text colorVariant="secondary" isItalic variant="body4" weightVariant="medium">
                      (Alt + C)
                    </Text>
                  </Inline>
                }
                onChange={() => setView(!showAdminView)}
              />
              <ToggleInput
                checked={showDemoView}
                data-testid="admin-action-menu__toggle-view"
                disabled={!isHovered}
                endNode={
                  <Inline alignItems="center" gap="xs">
                    <Text colorVariant="primary" variant="body2" weightVariant="medium">
                      {prefixNS('toggleDemoView')}
                    </Text>
                    <Text colorVariant="secondary" isItalic variant="body4" weightVariant="medium">
                      (Alt + X)
                    </Text>
                  </Inline>
                }
                onChange={() => {
                  setDemoView(!showDemoView);
                }}
              />
            </Stack>
            <Draft.Popover
              anchorEl={anchorEls.current ? anchorEls[anchorEls.current] : null}
              isOpen={Boolean(anchorEls.current && anchorEls[anchorEls.current])}
              middleware={[
                autoPlacement({
                  padding: 8,
                  rootBoundary: 'document',
                  allowedPlacements: ['left', 'top', 'bottom'],
                }),
              ]}
              onOpenChange={() => setAnchorEls({ ...anchorEls, [anchorEls.current]: null, current: '' })}
              placement="left"
            >
              <Draft.PopoverContent
                css={css`
                  color: ${theme.palette.background.default};
                  z-index: ${theme.zIndex.tooltip + 1};
                  box-sizing: border-box;
                  border: none;
                  background-color: ${theme.palette.background.tooltip};
                  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
                  border-radius: ${theme.sizes.x2};
                  display: flex;
                  flex-flow: column nowrap;
                `}
                dataTestId={`admin-action-menu__popover-${anchorEls.current}`}
              >
                <Inline
                  alignItems="center"
                  data-testid="filter-menu-header"
                  gap="s"
                  justifyContent="space-between"
                  p="s"
                  width="scale"
                >
                  {match(anchorEls.current)
                    .with('flags', () => (
                      <React.Fragment>
                        <Text
                          colorVariant="inherit"
                          variant="body2"
                          weightVariant="semiBold"
                        >{`${Object.keys(ldFlags).length} ${prefixNS('flag', { count: Object.keys(ldFlags).length })}`}</Text>
                        <CopyOutlined
                          css={css`
                            ${theme.mixins.body2(theme)}
                          `}
                          data-testid="admin-action-menu__btn--copy-flags"
                          onClick={() => {
                            copy(JSON.stringify(ldFlags));
                            trigger({ content: commonT('copied') });
                          }}
                          title={commonT('copy')}
                        />
                      </React.Fragment>
                    ))
                    .with('capabilities', () => (
                      <React.Fragment>
                        <Text
                          colorVariant="inherit"
                          variant="body2"
                          weightVariant="semiBold"
                        >{`${capabilities.length} ${commonT('permission', { count: capabilities.length })}`}</Text>
                        <CopyOutlined
                          css={css`
                            ${theme.mixins.body2(theme)}
                          `}
                          data-testid="admin-action-menu__btn--copy-capabilities"
                          onClick={() => {
                            copy(JSON.stringify(capabilities));
                            trigger({ content: commonT('copied') });
                          }}
                          title={commonT('copy')}
                        />
                      </React.Fragment>
                    ))
                    .with('customerToggles', () => (
                      <React.Fragment>
                        <Text
                          colorVariant="inherit"
                          variant="body2"
                          weightVariant="semiBold"
                        >{`${customerToggles.length} ${prefixNS('toggle', { count: customerToggles.length })}`}</Text>
                        <CopyOutlined
                          css={css`
                            ${theme.mixins.body2(theme)}
                          `}
                          data-testid="admin-action-menu__btn--copy-org-toggle"
                          onClick={() => {
                            copy(JSON.stringify(customerToggles));
                            trigger({ content: commonT('copied') });
                          }}
                          title={commonT('copy')}
                        />
                      </React.Fragment>
                    ))
                    .otherwise(() => null)}
                </Inline>
                <Hr backgroundColor="border" />
                <Stack data-testid="filter-menu-body" gap={0} maxHeight="x40" overflowY="auto" p="s">
                  {match(anchorEls.current)
                    .with('flags', () => (
                      <React.Fragment>
                        {Object.keys(ldFlags)
                          .sort((a, b) => a.localeCompare(b))
                          .map((flag, idx) => {
                            return (
                              <Inline
                                key={`${flag}-${idx}`}
                                alignItems="center"
                                data-testid={`admin-action-menu__toggle-${flag}`}
                                width="scale"
                              >
                                <Text
                                  colorVariant={ldFlags[flag] ? 'positive' : 'negative'}
                                  variant="body3"
                                  weightVariant="medium"
                                >
                                  {ldFlags[flag] ? (
                                    <CheckCircleFilled data-testid={`admin-action-menu__toggle-${flag}--checked`} />
                                  ) : (
                                    <CloseCircleOutlined data-testid={`admin-action-menu__toggle-${flag}--unchecked`} />
                                  )}
                                </Text>
                                <Text colorVariant="inherit" variant="body3" weightVariant="medium">
                                  {flag}
                                </Text>
                              </Inline>
                            );
                          })}
                      </React.Fragment>
                    ))
                    .with('capabilities', () => (
                      <React.Fragment>
                        {capabilities
                          .slice()
                          .sort()
                          .map((flag, idx) => {
                            return (
                              <Inline
                                key={`${flag}-${idx}`}
                                alignItems="center"
                                data-testid={`admin-action-menu__toggle-${flag}`}
                                justifyContent="space-between"
                                width="scale"
                              >
                                <Text colorVariant="inherit" variant="body3" weightVariant="medium">
                                  {flag}
                                </Text>
                              </Inline>
                            );
                          })}
                      </React.Fragment>
                    ))
                    .with('customerToggles', () => (
                      <React.Fragment>
                        {availableToggles
                          .slice()
                          .sort((a, b) => {
                            const aIncluded = customerToggles.includes(a.name);
                            const bIncluded = customerToggles.includes(b.name);
                            if (aIncluded && !bIncluded) return -1;
                            if (!aIncluded && bIncluded) return 1;
                            return a.name.localeCompare(b.name);
                          })
                          .map((flag) => {
                            const isFlagToggled = customerToggles.find((f) => flag.name === f);
                            return (
                              <Inline
                                key={`${flag.uuid}`}
                                alignItems="center"
                                data-testid={`admin-action-menu__org-toggle-${flag}`}
                                width="scale"
                              >
                                <Text colorVariant={isFlagToggled ? 'positive' : 'negative'} variant="body3">
                                  {isFlagToggled ? (
                                    <CheckCircleFilled data-testid={`admin-action-menu__org-toggle-${flag}--checked`} />
                                  ) : (
                                    <CloseCircleOutlined
                                      data-testid={`admin-action-menu__org-toggle-${flag}--unchecked`}
                                    />
                                  )}
                                </Text>
                                <Text colorVariant="inherit" title={flag.name} variant="body3" weightVariant="medium">
                                  {flag.name}
                                </Text>
                              </Inline>
                            );
                          })}
                      </React.Fragment>
                    ))
                    .otherwise(() => null)}
                </Stack>
              </Draft.PopoverContent>
            </Draft.Popover>
          </Stack>
        </div>
      </React.Fragment>,
      document.body
    )
  );
};

AdminActionMenuInternal.displayName = 'AdminActionMenuInternal';

export const AdminActionMenu = React.forwardRef(AdminActionMenuInternal);

// eslint-disable-next-line no-redeclare
export namespace AdminActionMenu {
  export type Props = _Props;
}
