import { useSelector } from 'react-redux';
import { selectCustomerData } from '@studio/store';

const useHasApiAccess = (): { hasApiAccess: boolean; hasVodApiAccess: boolean; hasWebApiAccess: boolean } => {
  const customerInfo = useSelector(selectCustomerData);
  const vodPlan = customerInfo?.vodPlan;
  const webPlan = customerInfo?.webPlan;
  const hasVodApiAccess = Boolean(vodPlan && vodPlan.apiRequestsPerMinute > 0);
  const hasWebApiAccess = Boolean(webPlan && webPlan.apiRequestsPerMinute > 0);
  return {
    hasApiAccess: hasVodApiAccess || hasWebApiAccess,
    hasVodApiAccess,
    hasWebApiAccess,
  };
};

export default useHasApiAccess;
