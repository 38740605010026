import { root, id } from './clients';
import * as idEndpoints from './id';
import { roles } from './roles';

export const clients = {
  ...root,
  id: {
    ...id,
    ...idEndpoints,
  },
  roles,
};
