import * as React from 'react';
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  CopyOutlined,
  FlagOutlined,
  LockOutlined,
  SettingOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import type { Theme } from '@emotion/react';
import { css, useTheme } from '@emotion/react';
import {
  Inline,
  Text,
  Stack,
  Draft,
  Hr,
  ListItem,
  RightAlt,
  Badge,
  useThemeConfig,
  useToast,
} from '@resi-media/resi-ui';
import { Command } from 'cmdk';
import copy from 'copy-to-clipboard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LAUNCHDARKLY_TOGGLES } from '@studio/constants/launchdarkly-toggle-constants';
import UrlPaths from '@studio/constants/url-paths';
import { useAdmin } from '@studio/contexts/admin';
import { useQrClick } from '@studio/hooks';
import { useHasApiAccess } from '@studio/hooks/use-has-api-access';
import { useIsVodPlanType } from '@studio/hooks/use-is-vod-plan-type';
import { useLibraryTrialAccess } from '@studio/hooks/use-library-trial-access';
import { usePrefix } from '@studio/hooks/use-prefix';
import { useRtmpAccess } from '@studio/hooks/use-rtmp-access';
import { useRtmpDiscoverability } from '@studio/hooks/use-rtmp-discoverability';
import { PlanTypeIndicator } from '@studio/pages/AdminSearch/components/PlanTypeIndicator';
import { useTypedSelector } from '@studio/store';
import { selectFilter, selectOrgs, set } from '@studio/store/admin/search';
import { selectUserName } from '@studio/store/authentication';
import { selectCustomerData } from '@studio/store/customer';
import type { SideMenuCategory, SideMenuSection } from '@studio/types/navigation';
import { Authorized } from '../Authorized';
import { ADMIN_MENU_CONFIG, getAnalyticsConfig, getLibraryConfig, getSettingsConfig } from '../SideNavMenu';
import { SwitchOrgModal, type SwitchOrgModalState } from '../SwitchOrg';

const extractSectionsWithRoutes = (config: SideMenuCategory[]): (SideMenuSection & { routes: string[] })[] => {
  const sectionsWithRoutes: (SideMenuSection & { routes: string[] })[] = [];

  config.forEach((category) => {
    category.sections.forEach((section) => {
      if (section.routes && section.routes.length > 0) {
        sectionsWithRoutes.push(section as SideMenuSection & { routes: string[] });
      }

      if (section.subMenu) {
        section.subMenu.forEach((subsection) => {
          if (subsection.routes.length > 0) {
            sectionsWithRoutes.push({ ...subsection, icon: undefined });
          }
        });
      }
    });
  });

  return sectionsWithRoutes;
};

type _Props = {
  is24HrView?: boolean;
  onTimeToggle?: () => void;
};

/* eslint-disable import/export */
const AdminCmdKInternal = ({ is24HrView, onTimeToggle }: _Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { commonT, prefixNS, prefixT } = usePrefix('navigation:');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const listRef = React.useRef(null);
  const [value, setValue] = React.useState('linear');
  const { onCloseReset } = Draft.ModalContext.useModal();
  const { setModal, setModalState } = Draft.ModalContext.useModal<SwitchOrgModalState>();
  const hasLibraryTrialAccess = useLibraryTrialAccess();
  const { setMode } = useThemeConfig();
  const username = useSelector(selectUserName);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { trigger } = useToast();
  const { qrClickOverlaysAvailable } = useQrClick();
  const navigate = useNavigate();
  const filter = useSelector(selectFilter);
  const [searchValue, setSearchValue] = React.useState(filter);
  const { favoriteCustomers, setCustomerData, setDemoView, setView, showAdminView, showDemoView } = useAdmin();
  const { hasApiAccess } = useHasApiAccess();
  const hasRtmpDiscoverability = useRtmpDiscoverability();
  const vodPlanInfo = useIsVodPlanType();
  const hasRtmpAccess = useRtmpAccess();
  const isFetching = useTypedSelector((state) => state.admin.search.isFetching);
  const searchedOrganizations = useSelector(selectOrgs);
  const customerInfo = useSelector(selectCustomerData);
  const hasMultiSitePlan = Boolean(customerInfo?.multisitePlanId);
  const { [LAUNCHDARKLY_TOGGLES.QRCLICK_TAB]: qrClickTab } = useFlags();

  const revisedConfig = React.useMemo(
    () =>
      extractSectionsWithRoutes([
        ...ADMIN_MENU_CONFIG.categories,
        ...getAnalyticsConfig(hasLibraryTrialAccess).categories,
        ...getLibraryConfig(vodPlanInfo, showAdminView).categories,
        ...getSettingsConfig({
          hasApiAccess,
          hasMultiSitePlan,
          hasQrClickAccess: qrClickTab,
          qrClickOverlaysAvailable,
          hasRtmpAccess,
          hasRtmpDiscoverability,
        }).categories,
      ]),
    [
      hasApiAccess,
      hasLibraryTrialAccess,
      hasMultiSitePlan,
      qrClickOverlaysAvailable,
      hasRtmpAccess,
      hasRtmpDiscoverability,
      qrClickTab,
      showAdminView,
      vodPlanInfo,
    ]
  );

  return (
    <Command
      ref={ref}
      css={(theme) => css`
        overflow: hidden;

        [cmdk-input] {
          border: none;
          width: 100%;
          ${theme.mixins.body2(theme)}
          padding: ${theme.spacing.s} ${theme.spacing.m};
          outline: none;
          background: transparent;
          color: ${theme.palette.text.primary};

          &::placeholder {
            color: ${theme.palette.text.disabled};
          }
        }

        [cmdk-list] {
          padding: 0 ${theme.spacing.s};
          height: 100%;
          overflow: auto;
          overscroll-behavior: contain;
          scroll-padding-block-end: ${theme.spacing.x10};
          transition: 100ms ease;
          transition-property: height;
          padding-bottom: ${theme.spacing.x10};

          .side-nav__icon {
            font-size: ${theme.typography.pxToRem(22)};
            color: ${theme.palette.text.primary};
          }
          ${theme.mq.xl} {
            height: ${theme.spacing.x96};
          }
        }

        [cmdk-group-heading] {
          user-select: none;
          ${theme.mixins.body3(theme)}
          color: ${theme.palette.text.secondary};
          padding: 0 ${theme.spacing.s};
          font-weight: ${theme.typography.fontWeight.medium};
          display: flex;
          align-items: center;
          text-transform: capitalize;
          margin: ${theme.spacing.s} 0 ${theme.spacing.s} ${theme.spacing.xs};
        }

        [cmdk-empty] {
          display: flex;
          align-items: center;
          justify-content: center;
          height: ${theme.spacing.x16};
          white-space: pre-wrap;
          ${theme.mixins.body3(theme)}
          color: ${theme.palette.text.secondary};
        }
      `}
      data-testid="admin-cmd-k"
      onValueChange={(v) => {
        setValue(v);
      }}
      shouldFilter={!isFetching}
      value={value}
    >
      <Draft.ModalHeader
        customCss={{
          container: (theme: Theme) => css`
            padding: ${theme.spacing.xs} ${theme.spacing.s} !important;
          `,
          header: css`
            width: 100%;
          `,
        }}
        header={
          <Command.Input
            ref={inputRef}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            data-testid="admin-cmd-k__input"
            onValueChange={(v) => {
              dispatch(set.filter(v));
              dispatch(set.search());
              setSearchValue(v);
            }}
            placeholder={prefixNS('searchCmdK')}
            value={searchValue}
          />
        }
        onClose={onCloseReset}
      />
      <Hr />
      <Draft.ModalBody
        css={css({
          height: '100%',
        })}
        data-testid="admin-cmd-k__body"
        id="admin-cmd-k__body"
        paddingVariant="none"
      >
        <Command.List ref={listRef}>
          {!isFetching && <Command.Empty data-testid="admin-cmd-k__empty">{commonT('noResults')}</Command.Empty>}
          <Command.Group heading={commonT('organization_plural')}>
            {isFetching ? (
              <Inline alignItems="center" gap="m" justifyContent="space-between" overflow="hidden" p="s">
                <Stack gap="0" overflow="hidden">
                  <Text
                    dataTestId="admin-cmd-k__fetching"
                    title={prefixNS('searchingOrgs')}
                    truncate
                    variant="body3"
                    weightVariant="regular"
                  >
                    {prefixNS('searchingOrgs')}
                  </Text>
                </Stack>
              </Inline>
            ) : (
              (!searchedOrganizations.length ? favoriteCustomers : searchedOrganizations).map((org) => {
                const mergedOrg = {
                  customerId: 'uuid' in org ? org.uuid : org.customerId,
                  customerName: 'uuid' in org ? org.name : org.customerName,
                  masterEcg: org.masterEcg,
                };
                return (
                  <Command.Item
                    key={mergedOrg.customerId}
                    className="admin-cmd-k__org"
                    data-testid="admin-cmd-k__org"
                    keywords={[mergedOrg.customerName, mergedOrg.customerId, mergedOrg.masterEcg ?? ''].filter(Boolean)}
                    onSelect={() => {
                      setModalState({
                        customerName: mergedOrg.customerName,
                        customerId: mergedOrg.customerId,
                        username,
                        onSuccess: () => {
                          onCloseReset();
                          setCustomerData({
                            customerId: mergedOrg.customerId,
                            customerName: mergedOrg.customerName,
                            masterEcg: mergedOrg.masterEcg,
                          });
                          navigate(UrlPaths.ADMIN.CURRENT_ORG);
                        },
                      });
                      setModal(() => <SwitchOrgModal />, {
                        dataTestId: 'admin-cmd-k__modal--switch-org',
                        minHVariant: 's',
                      });
                    }}
                    value={mergedOrg.customerName}
                  >
                    <ListItem p="s">
                      <Inline alignItems="center" gap="m" justifyContent="space-between" overflow="hidden">
                        <Stack gap="0" overflow="hidden">
                          <Text title={mergedOrg.customerName} truncate variant="body2" weightVariant="semiBold">
                            {mergedOrg.customerName}
                          </Text>
                        </Stack>
                        {'uuid' in org && (
                          <PlanTypeIndicator
                            planName={org.planName}
                            vodPlanName={org.vodPlanName}
                            webPlanName={org.webPlanName}
                          />
                        )}
                      </Inline>
                    </ListItem>
                  </Command.Item>
                );
              })
            )}
          </Command.Group>
          <Command.Group heading={commonT('suggestion_plural')}>
            {revisedConfig.map((section, i) => {
              return (
                <Authorized
                  key={`section-${i}-${section.label}`}
                  includesAny
                  requiredPerms={section.requiredPerms}
                  requiredToggles={section.requiredToggles}
                >
                  <Command.Item
                    data-testid={`admin-cmd-k__cmd-${section.label}`}
                    keywords={[section.label]}
                    onSelect={() => {
                      navigate(section.routes[0]);
                      onCloseReset();
                    }}
                    value={section.routes[0]}
                  >
                    <ListItem height="x20">
                      <Inline alignItems="center" gap="m">
                        <Text variant="body3">{section.icon ?? <SettingOutlined className="side-nav__icon" />}</Text>
                        <Stack gap="0">
                          <Text variant="body3" weightVariant="semiBold">
                            {prefixT(section.label)}
                          </Text>
                          <Text as="div" colorVariant="disabled" variant="body3">
                            <Inline alignItems="center" gap="xs">
                              {section.routes[0]
                                .split('/')
                                .filter(Boolean)
                                .map((e, idx, arr) => {
                                  return (
                                    <React.Fragment key={`route-${e}-${idx}`}>
                                      {e}
                                      {idx !== arr.length - 1 && <RightAlt />}
                                    </React.Fragment>
                                  );
                                })}
                            </Inline>
                          </Text>
                          <Inline alignItems="center" pt="xs">
                            {section.requiredPerms.map((perm: string) => (
                              <Badge
                                key={perm}
                                colorVariant="inactive"
                                css={(theme) => css`
                                  ${theme.mixins.body6(theme)}
                                `}
                                dataTestId="admin-cmd-k__badge--perm"
                                gap="xs"
                                height="x4"
                                px="xs"
                              >
                                <LockOutlined />
                                {perm}
                              </Badge>
                            ))}
                            {section.requiredToggles?.map((flag: string) => (
                              <Badge
                                key={flag}
                                colorVariant="red"
                                css={(theme) => css`
                                  ${theme.mixins.body6(theme)}
                                `}
                                dataTestId="admin-cmd-k__badge--flag"
                                gap="xs"
                                height="x4"
                                px="xs"
                              >
                                <FlagOutlined />
                                {flag}
                              </Badge>
                            ))}
                          </Inline>
                        </Stack>
                      </Inline>
                    </ListItem>
                  </Command.Item>
                </Authorized>
              );
            })}
          </Command.Group>
          <Command.Group heading={commonT('command_plural')}>
            <Command.Item
              data-testid="admin-cmd-k__cmd-copy-uuid"
              keywords={['copy', 'customer', 'uuid', 'id']}
              onSelect={() => {
                copy(customerInfo?.uuid ?? '');
                trigger({ content: commonT('copied') });
                onCloseReset();
              }}
              value="copyUuid"
            >
              <ListItem height="x20">
                <Inline alignItems="center" gap="m">
                  <Text variant="body3">
                    <CopyOutlined className="side-nav__icon" />
                  </Text>
                  <Stack gap="0">
                    <Text variant="body3" weightVariant="semiBold">
                      {`${commonT('copy')} Customer UUID`}
                    </Text>
                  </Stack>
                </Inline>
              </ListItem>
            </Command.Item>
            <Command.Item
              data-testid="admin-cmd-k__cmd-copy-ecg"
              keywords={['copy', 'customer', 'ecg', 'master']}
              onSelect={() => {
                if (!customerInfo?.masterEcg) {
                  trigger({ content: 'No Customer MasterEcg' });
                } else {
                  copy(customerInfo.masterEcg);
                  trigger({ content: commonT('copied') });
                  onCloseReset();
                }
              }}
              value="copyMasterEcg"
            >
              <ListItem disabled={!customerInfo?.masterEcg} height="x20">
                <Inline alignItems="center" gap="m">
                  <Text variant="body3">
                    <CopyOutlined className="side-nav__icon" />
                  </Text>
                  <Stack gap="0">
                    <Text variant="body3" weightVariant="semiBold">
                      {`${commonT('copy')} Customer MasterEcg`}
                    </Text>
                  </Stack>
                </Inline>
              </ListItem>
            </Command.Item>
            <Command.Item
              data-testid="admin-cmd-k__cmd-customer"
              keywords={['hide', 'customer', 'view', 'toggle', 'toggles']}
              onSelect={() => {
                setView(!showAdminView);
                onCloseReset();
              }}
              value="customerView"
            >
              <ListItem height="x20">
                <Inline alignItems="center" gap="m">
                  <Text variant="body3">
                    <ToolOutlined className="side-nav__icon" />
                  </Text>
                  <Stack gap="0">
                    <Text variant="body3" weightVariant="semiBold">
                      {prefixNS('customerView')}
                    </Text>
                    <Text as="div" colorVariant="disabled" variant="body3">
                      <Inline alignItems="center" gap="xs">
                        <span>{`${commonT('current')}:`}</span>
                        {!showAdminView ? (
                          <CheckCircleFilled
                            data-testid="admin-cmd-k__cmd-customer--checked"
                            style={{ color: theme.palette.positive.main }}
                          />
                        ) : (
                          <CloseCircleOutlined
                            data-testid="admin-cmd-k__cmd-customer--unchecked"
                            style={{ color: theme.palette.negative.main }}
                          />
                        )}
                      </Inline>
                    </Text>
                    <Inline alignItems="center" pt="xs">
                      <Badge
                        colorVariant="purple"
                        css={(theme) => css`
                          ${theme.mixins.body6(theme)}
                        `}
                        gap="xs"
                        height="x4"
                        px="xs"
                      >
                        Alt + C
                      </Badge>
                    </Inline>
                  </Stack>
                </Inline>
              </ListItem>
            </Command.Item>
            <Command.Item
              data-testid="admin-cmd-k__cmd-dark-mode"
              keywords={['dark', 'mode', 'palette', 'light', 'toggle']}
              onSelect={() => {
                setMode(theme.palette.activeMode === 'dark' ? 'light' : 'dark');
                onCloseReset();
              }}
              value="darkMode"
            >
              <ListItem height="x20">
                <Inline alignItems="center" gap="m">
                  <Text variant="body3">
                    <ToolOutlined className="side-nav__icon" />
                  </Text>
                  <Stack gap="0">
                    <Text variant="body3" weightVariant="semiBold">
                      {prefixNS('darkMode')}
                    </Text>
                    <Text as="div" colorVariant="disabled" variant="body3">
                      <Inline alignItems="center" gap="xs">
                        <span>{`${commonT('current')}:`}</span>
                        {theme.palette.activeMode === 'dark' ? (
                          <CheckCircleFilled
                            data-testid="admin-cmd-k__cmd-dark-mode--checked"
                            style={{ color: theme.palette.positive.main }}
                          />
                        ) : (
                          <CloseCircleOutlined
                            data-testid="admin-cmd-k__cmd-dark-mode--unchecked"
                            style={{ color: theme.palette.negative.main }}
                          />
                        )}
                      </Inline>
                    </Text>
                    <Inline alignItems="center" pt="xs">
                      <Inline alignItems="center">
                        <Badge
                          colorVariant="purple"
                          css={(theme) => css`
                            ${theme.mixins.body6(theme)}
                          `}
                          gap="xs"
                          height="x4"
                          px="xs"
                        >
                          Alt + V
                        </Badge>
                      </Inline>
                    </Inline>
                  </Stack>
                </Inline>
              </ListItem>
            </Command.Item>
            <Command.Item
              data-testid="admin-cmd-k__cmd-24-hour-view"
              keywords={['24 Hour View', 'time', 'mode', 'toggle', 'view']}
              onSelect={() => {
                onTimeToggle?.();
                onCloseReset();
              }}
              value="24HourView"
            >
              <ListItem height="x20">
                <Inline alignItems="center" gap="m">
                  <Text variant="body3">
                    <ToolOutlined className="side-nav__icon" />
                  </Text>
                  <Stack gap="0">
                    <Text variant="body3" weightVariant="semiBold">
                      {prefixNS('24HourView')}
                    </Text>
                    <Text as="div" colorVariant="disabled" variant="body3">
                      <Inline alignItems="center" gap="xs">
                        <span>{`${commonT('current')}:`}</span>
                        {is24HrView ? (
                          <CheckCircleFilled
                            data-testid="admin-cmd-k__cmd-24-hour-view--checked"
                            style={{ color: theme.palette.positive.main }}
                          />
                        ) : (
                          <CloseCircleOutlined
                            data-testid="admin-cmd-k__cmd-24-hour-view--unchecked"
                            style={{ color: theme.palette.negative.main }}
                          />
                        )}
                      </Inline>
                    </Text>
                    <Inline alignItems="center" pt="xs">
                      <Badge
                        colorVariant="purple"
                        css={(theme) => css`
                          ${theme.mixins.body6(theme)}
                        `}
                        gap="xs"
                        height="x4"
                        px="xs"
                      >
                        Alt + Z
                      </Badge>
                    </Inline>
                  </Stack>
                </Inline>
              </ListItem>
            </Command.Item>
            <Command.Item
              data-testid="admin-cmd-k__cmd-demo"
              keywords={['hide', 'demo', 'view', 'toggle']}
              onSelect={() => {
                setDemoView(!showDemoView);
                onCloseReset();
              }}
              value="demoView"
            >
              <ListItem height="x20">
                <Inline alignItems="center" gap="m">
                  <Text variant="body3">
                    <ToolOutlined className="side-nav__icon" />
                  </Text>
                  <Stack gap="0">
                    <Text variant="body3" weightVariant="semiBold">
                      {prefixNS('toggleDemoView')}
                    </Text>
                    <Text as="div" colorVariant="disabled" variant="body3">
                      <Inline alignItems="center" gap="xs">
                        <span>{`${commonT('current')}:`}</span>
                        {showDemoView ? (
                          <CheckCircleFilled
                            data-testid="admin-cmd-k__cmd-demo--checked"
                            style={{ color: theme.palette.positive.main }}
                          />
                        ) : (
                          <CloseCircleOutlined
                            data-testid="admin-cmd-k__cmd-demo--unchecked"
                            style={{ color: theme.palette.negative.main }}
                          />
                        )}
                      </Inline>
                    </Text>
                    <Inline alignItems="center" pt="xs">
                      <Badge
                        colorVariant="purple"
                        css={(theme) => css`
                          ${theme.mixins.body6(theme)}
                        `}
                        gap="xs"
                        height="x4"
                        px="xs"
                      >
                        Alt + X
                      </Badge>
                    </Inline>
                  </Stack>
                </Inline>
              </ListItem>
            </Command.Item>
          </Command.Group>
        </Command.List>
      </Draft.ModalBody>
    </Command>
  );
};

AdminCmdKInternal.displayName = 'AdminCmdKInternal';

export const AdminCmdK = React.forwardRef(AdminCmdKInternal);

// eslint-disable-next-line no-redeclare
export namespace AdminCmdK {
  export type Props = _Props;
}
