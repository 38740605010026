import { HTTP_METHODS } from '@studio/constants/axios';
import { STATUS_V1 } from '@studio/constants/env-variables';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${STATUS_V1}/encoders/online?minutes=2`,
    method: HTTP_METHODS.GET,
    response: {} as { online: number },
  },
};
