import * as React from 'react';
import {
  CalendarOutlined,
  DashboardOutlined,
  FolderOutlined,
  LineChartOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  LockFilled,
  UserOutlined,
  LockOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/react';
import { SecurityShield } from '@resi-media/resi-ui';
import i18next from 'i18next';
import type { Immutable } from 'immer';
import { REFERRALS_URL } from '@studio/constants/external-links-constants';
import { MenuItems, MenuPermissions, NavCategory, userHasAnySettingsPerms } from '@studio/constants/nav-constants';
import UrlPaths from '@studio/constants/url-paths';
import { getLibraryDefaultPath } from '@studio/helpers/navigation/getLibraryDefaultPath';
import type Permissions from '@studio/store/authentication/permissions';
import type { Customer, Library } from '@studio/types';

type AnalyticsSubMenuItems = {
  requiredPerms: Immutable<Permissions[]>;
  sectionCategory?: string;
  sections: SettingsSections[];
};

type MediaSubMenuItem = {
  icon?: React.ReactNode;
  isGated?: boolean;
  label: string;
  requiredPerms: Immutable<Permissions[]>;
  requiredToggles: Immutable<string[]>;
  routes: string[];
};

type MediaSubMenuItems = {
  requiredPerms: Immutable<Permissions[]>;
  sectionCategory: string;
  sections: MediaSubMenuItem[];
};

type SettingsSubMenuItem = {
  label: string;
  requiredPerms: Permissions[];
  routes: string[];
};

type SettingsSections = {
  icon?: React.ReactNode;
  label: string;
  requiredPerms: Immutable<Permissions[]>;
  requiredToggles?: Immutable<string[]>;
  requiredTogglesIncludesAny?: boolean;
  routes: string[];
  subMenu?: SettingsSubMenuItem[];
};

type AdminSubMenuItems = {
  requiredPerms: Immutable<Permissions[]>;
  sectionCategory?: string;
  sections: SettingsSections[];
};

type SettingsSubMenuItems = {
  requiredPerms: Immutable<Permissions[]>;
  sectionCategory: string;
  sections: SettingsSections[];
};

type RootSubMenuItems = {
  badgeTextKey?: string;
  href?: string;
  icon: React.ReactNode | null;
  isNested: boolean;
  label: string;
  navCategory: NavCategory | null;
  requiredPerms: Immutable<Permissions[]>;
  requiredToggles?: Immutable<string[]>;
  routes: string[] | null;
};

type HelpSubMenuItems = {
  label: string;
  routes: string[] | string;
};

type HamburgerMenuSections = {
  admin: AdminSubMenuItems[];
  analytics: AnalyticsSubMenuItems[];
  help: HelpSubMenuItems[];
  media: MediaSubMenuItems[];
  root: RootSubMenuItems[];
  settings: SettingsSubMenuItems[];
};

const getMenuSections = (
  customer: Customer.Get.Customer | null,
  canShowWebVideos: boolean,
  canShowEncoderVideos: boolean,
  hasLibraryTrialAccess: boolean,
  hasMultiSitePlan: boolean,
  { isEnterprise, isPremium, isPro, vodPlanId }: Library.Derived.VodPlanType,
  hasRtmpAccess: boolean,
  hasRtmpDiscoverability: boolean,
  showAdminView: boolean,
  hasApiAccess: boolean,
  hasQrClickAccess: boolean,
  hasQrClickOverlays: boolean,
  hasReferralProgram: boolean
): HamburgerMenuSections => {
  const deviceSettingsSections: SettingsSections[] = [
    {
      label: MenuItems.ENCODERS,
      routes: [UrlPaths.SETTINGS.ENCODERS],
      requiredPerms: MenuPermissions[MenuItems.ENCODERS].permissions,
    },
    {
      label: MenuItems.ENCODER_PRESETS,
      routes: [UrlPaths.SETTINGS.ENCODER_PRESETS],
      requiredPerms: MenuPermissions[MenuItems.ENCODER_PRESETS].permissions,
    },
    {
      label: MenuItems.ENCODER_CHANNELS,
      routes: [UrlPaths.SETTINGS.ENCODER_CHANNELS],
      requiredPerms: MenuPermissions[MenuItems.ENCODER_CHANNELS].permissions,
    },
  ];
  if (hasMultiSitePlan) {
    deviceSettingsSections.push({
      label: MenuItems.VENUES,
      routes: [UrlPaths.SETTINGS.VENUES],
      requiredPerms: MenuPermissions[MenuItems.VENUES].permissions,
    });
  }

  const statusSections: MediaSubMenuItem[] = [
    {
      label: MenuItems.RECENTLY_UPLOADED,
      routes: [UrlPaths.MEDIA.RECENTLY_UPLOADED],
      requiredPerms: MenuPermissions[MenuItems.RECENTLY_UPLOADED].permissions,
      requiredToggles: [],
    },
  ];
  if (showAdminView) {
    statusSections.push({
      icon: <SecurityShield />,
      label: MenuItems.RECENT_RTMP,
      routes: [UrlPaths.MEDIA.RECENT_RTMP],
      requiredPerms: MenuPermissions[MenuItems.RECENT_RTMP].permissions,
      requiredToggles: [],
    });
  }

  const mediaSection: MediaSubMenuItems[] = [
    {
      requiredPerms: [],
      sectionCategory: MenuItems.SAVED_MEDIA,
      sections: [
        {
          label: MenuItems.LIBRARY,
          routes: [UrlPaths.MEDIA.LIBRARY],
          requiredPerms: MenuPermissions[MenuItems.LIBRARY].permissions,
          requiredToggles: [],
          isGated: !vodPlanId,
        },
        {
          label: MenuItems.PLAYLISTS,
          routes: [UrlPaths.MEDIA.PLAYLISTS],
          requiredPerms: MenuPermissions[MenuItems.LIBRARY].permissions,
          requiredToggles: [],
          isGated: !vodPlanId,
        },
        {
          label: MenuItems.SITES,
          routes: [UrlPaths.MEDIA.SITES],
          requiredPerms: MenuPermissions[MenuItems.SITES].permissions,
          requiredToggles: [],
          isGated: !isEnterprise && !isPremium && !isPro,
        },
      ],
    },
    {
      requiredPerms: [],
      sectionCategory: MenuItems.EXPIRING_MEDIA,
      sections: [
        {
          label: MenuItems.WEB_VIDEOS,
          routes: [UrlPaths.MEDIA.EXPIRING_WEB_VIDEOS],
          requiredPerms: MenuPermissions[MenuItems.WEB_VIDEOS].permissions,
          requiredToggles: [],
        },
        {
          label: MenuItems.ENCODER_VIDEOS,
          routes: [UrlPaths.MEDIA.EXPIRING_ENCODER_VIDEOS],
          requiredPerms: MenuPermissions[MenuItems.ENCODER_VIDEOS].permissions,
          requiredToggles: [],
        },
      ],
    },
    {
      requiredPerms: [],
      sectionCategory: MenuItems.STATUS,
      sections: statusSections,
    },
  ];

  const webSettingsSections: SettingsSections[] = [
    {
      label: MenuItems.WEB_PRESETS,
      routes: [UrlPaths.SETTINGS.WEB_PRESETS],
      requiredPerms: MenuPermissions[MenuItems.WEB_PRESETS].permissions,
    },
    {
      label: MenuItems.WEB_CHANNELS,
      routes: [UrlPaths.SETTINGS.WEB_CHANNELS],
      requiredPerms: MenuPermissions[MenuItems.WEB_CHANNELS].permissions,
    },
    {
      label: MenuItems.SOCIAL_MEDIA,
      routes: [UrlPaths.SETTINGS.SOCIAL_MEDIA],
      requiredPerms: MenuPermissions[MenuItems.SOCIAL_MEDIA].permissions,
    },
  ];

  if (hasRtmpAccess || hasRtmpDiscoverability) {
    webSettingsSections.push({
      icon: hasRtmpDiscoverability && <LockOutlined data-testid="lock-icon" />,
      label: MenuItems.RTMP,
      routes: [UrlPaths.SETTINGS.RTMP_DESTINATIONS],
      requiredPerms: MenuPermissions[MenuItems.RTMP].permissions,
      requiredTogglesIncludesAny: true,
    });
  }

  webSettingsSections.push(
    {
      label: MenuItems.DESTINATION_GROUPS,
      routes: [UrlPaths.SETTINGS.DESTINATION_GROUPS],
      requiredPerms: MenuPermissions[MenuItems.DESTINATION_GROUPS].permissions,
    },
    {
      label: MenuItems.PLAYER_PROFILES,
      routes: [UrlPaths.SETTINGS.PLAYER_PROFILES],
      requiredPerms: MenuPermissions[MenuItems.PLAYER_PROFILES].permissions,
      requiredToggles: [],
    }
  );

  if (hasQrClickAccess) {
    webSettingsSections.push({
      icon: !hasQrClickOverlays && <LockOutlined data-testid="qr-click-lock-icon" />,
      label: MenuItems.QR_CLICK_OVERLAYS,
      routes: [UrlPaths.SETTINGS.QR_CLICK_OVERLAYS],
      requiredPerms: [],
    });
  }

  const rootSection: RootSubMenuItems[] = [
    {
      icon: <DashboardOutlined />,
      isNested: false,
      label: 'dashboard',
      navCategory: NavCategory.DASHBOARD,
      requiredPerms: [],
      routes: [UrlPaths.DASHBOARD.ROOT],
    },
    {
      icon: <FolderOutlined />,
      isNested: true,
      label: 'media',
      navCategory: NavCategory.MEDIA,
      requiredPerms: [],
      routes: [getLibraryDefaultPath(customer, canShowWebVideos, canShowEncoderVideos)],
    },
    {
      icon: <CalendarOutlined />,
      isNested: false,
      label: MenuItems.SCHEDULE,
      navCategory: NavCategory.SCHEDULE,
      requiredPerms: MenuPermissions[MenuItems.SCHEDULE].permissions,
      routes: [UrlPaths.SCHEDULE.ROOT],
    },
    {
      icon: <LineChartOutlined />,
      isNested: true,
      label: 'analytics',
      navCategory: NavCategory.ANALYTICS,
      requiredPerms: [],
      routes: [UrlPaths.ANALYTICS.ROOT],
    },
    {
      icon: <SettingOutlined />,
      isNested: true,
      label: 'settings',
      navCategory: NavCategory.SETTINGS,
      requiredPerms: userHasAnySettingsPerms,
      routes: null,
    },
    {
      icon: <SecurityShield />,
      isNested: true,
      label: 'admin',
      navCategory: NavCategory.ADMIN,
      requiredPerms: MenuPermissions[MenuItems.ADMIN].permissions,
      routes: null,
    },
  ];
  if (hasReferralProgram) {
    rootSection.push({
      href: REFERRALS_URL,
      icon: <NotificationOutlined />,
      isNested: false,
      label: 'referAFriend',
      navCategory: null,
      requiredPerms: [],
      routes: null,
    });
  }
  rootSection.push(
    {
      icon: <QuestionCircleOutlined />,
      isNested: true,
      label: 'help',
      navCategory: NavCategory.HELP,
      requiredPerms: [],
      routes: null,
    },
    {
      icon: <UserOutlined />,
      isNested: false,
      label: 'account',
      navCategory: NavCategory.ACCOUNT,
      requiredPerms: [],
      routes: [UrlPaths.SETTINGS.ACCOUNT],
    },
    {
      icon: (
        <LogoutOutlined
          css={(theme) => css`
            color: ${theme.palette.negative.main};
          `}
        />
      ),
      isNested: false,
      label: 'signOut',
      navCategory: null,
      requiredPerms: [],
      routes: null,
    }
  );

  return {
    root: rootSection,
    analytics: [
      {
        requiredPerms: [],
        sections: [
          {
            label: MenuItems.EVENT_ANALYTICS,
            routes: [UrlPaths.ANALYTICS.ROOT],
            requiredPerms: MenuPermissions[MenuItems.ANALYTICS].permissions,
            requiredToggles: [],
          },
        ],
      },
      {
        requiredPerms: [],
        sections: [
          {
            icon: hasLibraryTrialAccess ? <LockFilled /> : '',
            label: MenuItems.LIBRARY_ANALYTICS,
            routes: [UrlPaths.ANALYTICS.MEDIA_LIBRARY],
            requiredPerms: MenuPermissions[MenuItems.LIBRARY_ANALYTICS].permissions,
            requiredToggles: [],
          },
        ],
      },
    ],
    media: mediaSection,
    settings: [
      {
        requiredPerms: MenuPermissions[MenuItems.WEB_SETTINGS].permissions,
        sectionCategory: MenuItems.WEB_SETTINGS,
        sections: webSettingsSections,
      },
      {
        requiredPerms: MenuPermissions[MenuItems.DEVICE_SETTINGS].permissions,
        sectionCategory: MenuItems.DEVICE_SETTINGS,
        sections: deviceSettingsSections,
      },
      {
        requiredPerms: MenuPermissions[MenuItems.ACCOUNT_SETTINGS].permissions,
        sectionCategory: MenuItems.ACCOUNT_SETTINGS,
        sections: [
          {
            label: MenuItems.ORGANIZATION_DETAILS,
            routes: [
              UrlPaths.SETTINGS.ORGANIZATION_DETAILS,
              UrlPaths.SETTINGS.ORGANIZATION_DETAILS_BANDWIDTH_USAGE,
              UrlPaths.SETTINGS.ORGANIZATION_DETAILS_LIBRARY_SETTINGS,
            ],
            requiredPerms: MenuPermissions[MenuItems.ORGANIZATION_DETAILS].permissions,
          },
          {
            label: MenuItems.USERS,
            routes: [UrlPaths.SETTINGS.USERS],
            requiredPerms: MenuPermissions[MenuItems.USERS].permissions,
          },
          ...(hasApiAccess
            ? [
                {
                  label: MenuItems.API_ACCESS,
                  routes: [UrlPaths.SETTINGS.API_ACCESS],
                  requiredPerms: MenuPermissions[MenuItems.API_ACCESS].permissions,
                  requiredToggles: [],
                },
              ]
            : []),
        ],
      },
    ],
    admin: [
      {
        requiredPerms: MenuPermissions[MenuItems.ADMIN_SEARCH].permissions,
        sections: [
          {
            label: MenuItems.ADMIN_SEARCH,
            routes: [UrlPaths.ADMIN.ORGANIZATIONS],
            requiredPerms: MenuPermissions[MenuItems.ADMIN_SEARCH].permissions,
          },
        ],
      },
      {
        requiredPerms: [],
        sectionCategory: 'currentOrg',
        sections: [
          {
            label: 'overview',
            routes: [UrlPaths.ADMIN.CURRENT_ORG],
            requiredPerms: MenuPermissions[MenuItems.ADMIN_SEARCH].permissions,
          },
        ],
      },
    ],
    help: [
      {
        label: 'contactSupport',
        routes: [i18next.t('links:supportPage'), '_blank'],
      },
      {
        label: 'helpCenter',
        routes: [i18next.t('links:helpDocs'), '_blank'],
      },
      {
        label: 'status',
        routes: [i18next.t('links:statusPage'), '_blank'],
      },
    ],
  };
};

export default getMenuSections;
