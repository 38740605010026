import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V2 } from '@studio/constants/env-variables';
import type { Encoders } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V2}/encoders/search`,
    method: HTTP_METHODS.GET,
    query: {} as {
      limit?: number | null;
      model?: string | null;
      name?: string | null;
      reportedState?: string | null;
      version?: string | null;
    },
    response: [] as Encoders.Get.V2.EncoderSearch[],
  },
};
