import * as React from 'react';
import { produce } from 'immer';
import { createContainer } from 'react-tracked';

// TODO: Delete these and import them from resi-ui once they're exported correctly
type DimensionsState = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
};

type DimensionSetterHook<T extends Element> = DimensionsState & {
  setRef: React.RefCallback<T>;
};
// End of Comment Block

const ELEMENT_IDS = {
  APP_HEADER: 'app-header',
  ENCODER_SEARCH_CARDS: 'encoder-search-cards',
  ENCODER_SEARCH_CONTENT_HEADER: 'encoder-search-content-header',
  SYSTEM_LOGS_CONTENT_HEADER: 'system-logs-content-header',
  SYSTEM_LOGS_LIST_CARD: 'system-logs-list-card',
};

type DimensionContextState = {
  dimensionsMap: Map<string, DimensionsState>;
};

export const initialDimensionsState: DimensionContextState = {
  dimensionsMap: new Map(),
};

enum DIMENSION_ACTION_TYPES {
  ADD_DIMENSION,
}

type DimensionActions = {
  payload: { id: string; rect: DimensionsState };
  type: DIMENSION_ACTION_TYPES.ADD_DIMENSION;
};

const dimsReducer = (state: DimensionContextState, action: DimensionActions) => {
  switch (action.type) {
    case DIMENSION_ACTION_TYPES.ADD_DIMENSION:
      state.dimensionsMap.set(action.payload.id, action.payload.rect);
      break;
  }
};

type DimensionContextHook = DimensionContextState & {
  addDimension: (s: string, rect: DimensionsState) => void;
  getDimensions: (s: string[]) => DimensionsState;
  getDimensionsByProperty: (s: string[], property: keyof DimensionsState) => number;
};

const curriedReducerFunction = produce(dimsReducer);

const useValues = (): readonly [DimensionContextHook, () => void] => {
  const [localState, dispatch] = React.useReducer(curriedReducerFunction, {
    ...initialDimensionsState,
  });

  const addDimension = React.useCallback((s: string, rect: DimensionsState) => {
    dispatch({
      type: DIMENSION_ACTION_TYPES.ADD_DIMENSION,
      payload: { id: s, rect },
    });
  }, []);

  const getDimensions = React.useCallback(
    (s: string[]) => {
      return s.reduce<DimensionsState>(
        (acc, key) => {
          const currentDimensions = localState.dimensionsMap.get(key);
          if (currentDimensions) {
            for (const dimensionProperty in currentDimensions) {
              const typedDimensionProperty = dimensionProperty as keyof DimensionsState;
              acc = {
                ...acc,
                [typedDimensionProperty]: acc[typedDimensionProperty] + currentDimensions[typedDimensionProperty],
              };
            }
          }
          return acc;
        },
        {
          bottom: 0,
          height: 0,
          left: 0,
          right: 0,
          top: 0,
          width: 0,
        }
      );
    },
    [localState.dimensionsMap]
  );

  const getDimensionsByProperty = React.useCallback(
    (s: string[], property: keyof DimensionsState) => {
      return s.reduce((acc, key) => {
        const value = localState.dimensionsMap.get(key)?.[property];
        if (value) {
          acc += value;
        }
        return acc;
      }, 0);
    },
    [localState.dimensionsMap]
  );

  const state = React.useMemo(
    () => ({
      ...localState,
      addDimension,
      getDimensions,
      getDimensionsByProperty,
    }),
    [addDimension, getDimensions, getDimensionsByProperty, localState]
  );

  return [
    state,
    () => {
      throw new Error('use functions in the state');
    },
  ];
};

const {
  Provider: DimensionProvider,
  useTrackedState: useDimensions,
  useUpdate: useDimensionsUpdate,
} = createContainer<DimensionContextHook, () => void, { children: React.ReactNode }>(useValues);

export { DimensionProvider, useDimensions, useDimensionsUpdate, ELEMENT_IDS };
export type { DimensionContextHook, DimensionSetterHook, DimensionsState };
