import { produce } from 'immer';
import type { ActionType } from 'typesafe-actions';
import { createReducer } from 'typesafe-actions';
import { fetch, set } from './actions';
import type { MonitorState } from './types';

type Action = ActionType<typeof fetch | typeof set>;

export const initialState: MonitorState = {
  erroredEncoders: {
    isFetching: false,
    error: null,
    data: [],
    dataCache: [],
  },
  decoders: {
    isFetching: false,
    error: null,
    data: [],
    dataCache: [],
    filter: '',
    version: 'All Versions',
  },
};

const reducer = createReducer<MonitorState, Action>(initialState)
  .handleAction(
    fetch.decoders.cancel,
    produce((draft: MonitorState) => {
      draft.decoders.isFetching = false;
    })
  )
  .handleAction(
    fetch.decoders.failure,
    produce((draft: MonitorState, action: ActionType<typeof fetch.decoders.failure>) => {
      draft.decoders.isFetching = false;
      draft.decoders.error = action.payload;
    })
  )
  .handleAction(
    fetch.decoders.request,
    produce((draft: MonitorState) => {
      draft.decoders.isFetching = !draft.decoders.data.length;
      draft.decoders.error = null;
    })
  )
  .handleAction(
    fetch.decoders.success,
    produce((draft: MonitorState, action: ActionType<typeof fetch.decoders.success>) => {
      draft.decoders.isFetching = false;
      draft.decoders.data = action.payload.value;
      draft.decoders.dataCache = [...draft.decoders.dataCache, action.payload];
    })
  )
  .handleAction(
    fetch.erroredEncoders.cancel,
    produce((draft: MonitorState) => {
      draft.erroredEncoders.isFetching = false;
    })
  )
  .handleAction(
    fetch.erroredEncoders.failure,
    produce((draft: MonitorState, action: ActionType<typeof fetch.erroredEncoders.failure>) => {
      draft.erroredEncoders.isFetching = false;
      draft.erroredEncoders.error = action.payload;
    })
  )
  .handleAction(
    fetch.erroredEncoders.request,
    produce((draft: MonitorState) => {
      draft.erroredEncoders.isFetching = !draft.erroredEncoders.data.length;
      draft.erroredEncoders.error = null;
    })
  )
  .handleAction(
    fetch.erroredEncoders.success,
    produce((draft: MonitorState, action: ActionType<typeof fetch.erroredEncoders.success>) => {
      draft.erroredEncoders.isFetching = false;
      draft.erroredEncoders.data = action.payload.value;
      draft.erroredEncoders.dataCache = [...draft.erroredEncoders.dataCache, action.payload];
    })
  )
  .handleAction(
    set.decoders.filter,
    produce((draft: MonitorState, action: ActionType<typeof set.decoders.filter>) => {
      draft.decoders.filter = action.payload;
    })
  )
  .handleAction(
    set.decoders.version,
    produce((draft: MonitorState, action: ActionType<typeof set.decoders.version>) => {
      draft.decoders.version = action.payload;
    })
  );

export default reducer;
