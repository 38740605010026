import * as React from 'react';
import {
  CalendarOutlined,
  CopyFilled,
  CopyOutlined,
  DashboardOutlined,
  FolderOutlined,
  LineChartOutlined,
  LogoutOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { css, useTheme } from '@emotion/react';
import {
  Box,
  Draft,
  IconButton,
  Inline,
  List,
  ListItem,
  RLogo,
  Stack,
  Tab,
  TabGroup,
  Text,
  useBreakpoint,
  useToast,
} from '@resi-media/resi-ui';
import copy from 'copy-to-clipboard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { HEADER_HEIGHT, HEADER_HEIGHT_SUDO_USER } from '@studio/constants/dashboard-style-constants';
import { REFERRALS_URL } from '@studio/constants/external-links-constants';
import { LAUNCHDARKLY_TOGGLES } from '@studio/constants/launchdarkly-toggle-constants';
import UrlPaths from '@studio/constants/url-paths';
import { useAdmin } from '@studio/contexts/admin';
import { ELEMENT_IDS, useDimensionsSetter } from '@studio/contexts/dimensions';
import NavContext, { NAV_ACTION } from '@studio/contexts/nav';
import { getCurrentNavCategory } from '@studio/helpers/navigation/getCurrentNavCategory';
import { getLibraryDefaultPath } from '@studio/helpers/navigation/getLibraryDefaultPath';
import { useIsAuthorized, usePrefix } from '@studio/hooks';
import Permissions from '@studio/store/authentication/permissions';
import { selectCustomerData } from '@studio/store/customer';
import { HideOnTablet, HideOnDesktop } from '@studio/styles/utilities';
import { AdminActionMenu } from '../AdminActionMenu';
import { Authorized } from '../Authorized';
import { GoLiveButton } from '../GoLiveButton';
import { PopupMenu } from '../PopupMenu';
import { ProfilePopup } from '../ProfilePopup';
import { AppLogo, hamburgerButtonStyles, Header, IconAnchor, IconWrap } from './styles';

const AppHeader = () => {
  const { commonT, linkT, prefixNS } = usePrefix(`navigation:`);
  const location = useLocation();
  const { dispatch: contextDispatch, state } = React.useContext(NavContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>();
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { setRef } = useDimensionsSetter(ELEMENT_IDS.APP_HEADER, {
    ignoreDimensions: ['bottom', 'left', 'right', 'top', 'width'],
  });
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const mediaQuery = useBreakpoint();
  const { [LAUNCHDARKLY_TOGGLES.REFERRAL_PROGRAM]: referralProgram } = useFlags();
  const { trigger } = useToast();
  const customer = useSelector(selectCustomerData);
  const canShowWebVideos = useIsAuthorized([Permissions.WEB_EVENTS_GET], false);
  const canShowEncoderVideos = useIsAuthorized([Permissions.EVENTS_GET], false);
  const canShowScheduling = useIsAuthorized([Permissions.SCHEDULES_GET], false);
  const { currentCustomer, handleSwitchDefault, isResiAdmin, showSudo } = useAdmin();
  const canAccessOrgDetails = useIsAuthorized([Permissions.ORGANIZATION_DETAILS_GET]);
  const iconButtonSizeVariant = mediaQuery.xl ? 'm' : 's';

  const showReferralLink = referralProgram && mediaQuery.xxl;

  const settingsLandingsPageRoute = canAccessOrgDetails
    ? UrlPaths.SETTINGS.ORGANIZATION_DETAILS
    : UrlPaths.SETTINGS.ACCOUNT;

  const handleClick = (
    event: React.KeyboardEvent<HTMLButtonElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (event.currentTarget === anchorRef.current) {
      event.stopPropagation();
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openNewTab = (url: string): void => {
    window.open(url, '_blank');
  };

  const getNavigationIndex = React.useCallback(() => {
    const currentCategory = getCurrentNavCategory(location.pathname);
    if (canShowScheduling) return currentCategory;
    if (currentCategory && currentCategory > 1) {
      return currentCategory - 1;
    }
    return currentCategory;
  }, [canShowScheduling, location.pathname]);

  return (
    <Stack
      ref={setRef}
      css={(theme) => css`
        box-sizing: border-box;
        box-shadow: ${theme.shadows[1]};
        grid-area: header;
        position: absolute;
        overflow: hidden;
        font-size: 14px;
        width: 100%;
        height: ${showSudo ? HEADER_HEIGHT_SUDO_USER : HEADER_HEIGHT}px;
        z-index: ${theme.zIndex.header};

        ${theme.mq.lg} {
          font-size: 16px;
          position: static;
          width: unset;
          overflow: visible;
        }
      `}
      dataTestId="app-header__container"
      gap={0}
    >
      {showSudo && (
        <Stack
          alignItems="center"
          background="brand.resiRed"
          color="white"
          dataTestId="app-header__banner--sudo"
          height="100%"
          justifyContent="center"
          overflow="hidden"
          px="m"
          py="xs"
        >
          <Box
            alignItems="center"
            display="flex"
            flexDir={{ xs: 'row', xl: 'row' }}
            flexWrap="nowrap"
            gap={{ xs: 's', xl: 'l' }}
            justifyContent="right"
            width="scale"
          >
            <Stack gap="0" mr="xs" overflow="hidden">
              <Inline
                alignItems="center"
                justifyContent="right"
                {...(!mediaQuery.xl && {
                  overflow: 'hidden',
                })}
                gap="xs"
              >
                <Text
                  colorVariant="inherit"
                  dataTestId="app-header__sudo--title"
                  variant={mediaQuery.xl ? 'body2' : 'body3'}
                  whiteSpace="nowrap"
                >
                  {prefixNS('orgOverride')}&#58;
                </Text>
                <Text
                  colorVariant="inherit"
                  dataTestId="app-header__sudo--customer"
                  title={currentCustomer}
                  truncate
                  variant={mediaQuery.xl ? 'h5' : 'body3'}
                  weightVariant="semiBold"
                >
                  {currentCustomer}
                </Text>
              </Inline>
              <Inline
                alignItems="center"
                justifyContent="right"
                {...(!mediaQuery.xl && {
                  overflow: 'hidden',
                })}
              >
                <Inline alignItems="center" overflow="hidden">
                  <Text
                    colorVariant="inherit"
                    dataTestId="app-header__sudo--id"
                    title={customer?.uuid}
                    truncate
                    variant={mediaQuery.xl ? 'body3' : 'body4'}
                  >
                    {customer?.uuid}
                  </Text>
                  <CopyOutlined
                    data-testid="app-header__icon--copy-uuid"
                    onClick={() => {
                      copy(customer?.uuid ?? '');
                      trigger({ content: commonT('copied') });
                    }}
                    title={commonT('copy')}
                  />
                </Inline>
                <span>|</span>
                <Inline alignItems="center" overflow="hidden">
                  <Text
                    colorVariant="inherit"
                    dataTestId="app-header__sudo--ecg"
                    title={customer?.masterEcg ?? ''}
                    truncate
                    variant={mediaQuery.xl ? 'body3' : 'body4'}
                  >
                    {customer?.masterEcg ?? 'ECG-NULL'}
                  </Text>
                  <CopyOutlined
                    data-testid="app-header__icon--copy-ecg"
                    onClick={() => {
                      copy(customer?.masterEcg ?? 'ECG-NULL');
                      trigger({ content: commonT('copied') });
                    }}
                    title={commonT('copy')}
                  />
                </Inline>
              </Inline>
            </Stack>
            <span
              css={css({
                width: theme.sizes.px,
                height: mediaQuery.xl ? theme.sizes.scale : theme.sizes['10/12'],
                backgroundColor: theme.palette.common.white,
              })}
            />
            <Inline alignItems="center">
              <IconButton
                dataTestId="app-header__icon--copy-customer"
                label={prefixNS('copy')}
                onClick={() => {
                  copy(
                    JSON.stringify({
                      name: currentCustomer,
                      uuid: customer?.uuid ?? '',
                      masterEcg: customer?.masterEcg ?? '',
                    })
                  );
                  trigger({ content: commonT('copied') });
                }}
                sizeVariant={iconButtonSizeVariant}
                title={prefixNS('copyOrgInfo')}
              >
                <CopyFilled style={{ color: theme.palette.common.white }} />
              </IconButton>
              <IconButton
                as={Link}
                dataTestId="app-header__icon--route-org"
                label={prefixNS('orgSearch')}
                sizeVariant={iconButtonSizeVariant}
                title={prefixNS('orgSearch')}
                to={UrlPaths.ADMIN.ORGANIZATIONS}
              >
                <SearchOutlined style={{ color: theme.palette.common.white }} />
              </IconButton>
              <IconButton
                label={prefixNS('exitOverride')}
                onClick={handleSwitchDefault}
                sizeVariant={iconButtonSizeVariant}
                title={prefixNS('exitOverride')}
              >
                <LogoutOutlined style={{ color: theme.palette.common.white }} />
              </IconButton>
            </Inline>
          </Box>
        </Stack>
      )}
      <Header
        css={(theme) => [
          css({
            backgroundColor: showSudo ? theme.palette.negative.background : theme.palette.background.paper,
          }),
        ]}
        data-testid="app-header"
      >
        <Inline alignItems="center">
          <AppLogo>
            <RLogo color={theme.palette.brand.resiRed} />
          </AppLogo>
          <HideOnTablet display="flex">
            <TabGroup index={getNavigationIndex()} sizeVariant="m">
              <Tab
                as={Link}
                dataTestId="app-header__tab--dashboard"
                startNode={<DashboardOutlined />}
                to={UrlPaths.DASHBOARD.ROOT}
              >
                {prefixNS('dashboard')}
              </Tab>
              <Tab
                as={Link}
                dataTestId="app-header__tab--media"
                startNode={<FolderOutlined />}
                to={getLibraryDefaultPath(customer, canShowWebVideos, canShowEncoderVideos)}
              >
                {prefixNS('media')}
              </Tab>
              <Authorized requiredPerms={[Permissions.SCHEDULES_GET]}>
                <Tab
                  as={Link}
                  dataTestId="app-header__tab--schedule"
                  startNode={<CalendarOutlined />}
                  to={UrlPaths.SCHEDULE.ROOT}
                >
                  {prefixNS('schedule')}
                </Tab>
              </Authorized>
              <Tab
                as={Link}
                dataTestId="app-header__tab--analytics"
                startNode={<LineChartOutlined />}
                to={UrlPaths.ANALYTICS.ROOT}
              >
                {prefixNS('analytics')}
              </Tab>
              <Tab
                as={Link}
                dataTestId="app-header__tab--settings"
                startNode={<SettingOutlined />}
                to={settingsLandingsPageRoute}
              >
                {prefixNS('settings')}
              </Tab>
            </TabGroup>
          </HideOnTablet>
        </Inline>
        <Inline alignItems="center">
          <HideOnDesktop>
            <Inline alignItems="center" gap="xl">
              <GoLiveButton dataTestId="app-header__btn--go-live-mobile" sizeVariant="s" />
              <Inline
                dataTestId="app-header__btn--menu"
                onClick={() => contextDispatch({ type: NAV_ACTION.TOGGLE_HAMBURGER_MENU })}
                style={{ cursor: 'pointer', height: '12px', marginRight: theme.spacing.l }}
              >
                <div css={hamburgerButtonStyles(theme, state.isHamburgerMenuOpen)} data-testid="app-header__hamburger">
                  <MenuOutlined style={{ color: theme.palette.text.title }} />
                </div>
              </Inline>
            </Inline>
          </HideOnDesktop>
          <HideOnTablet>
            <Inline alignItems="center">
              {showReferralLink && (
                <Tab
                  as={Link}
                  dataTestId="app-header__tab--referrals"
                  startNode={<NotificationOutlined />}
                  target="_blank"
                  to={REFERRALS_URL}
                >
                  {prefixNS('referAFriend')}
                </Tab>
              )}
              <Inline alignItems="center" gap="m">
                <GoLiveButton data-trackid="go-live-button-desktop" dataTestId="app-header__btn--go-live" />
                <IconWrap isActiveTab={open}>
                  <span
                    data-trackid="studio-tutorial-trigger"
                    style={{ height: '1px', width: '1px', position: 'absolute' }}
                  ></span>
                  <IconAnchor ref={anchorRef} data-testid="app-header__icon--help" onClick={handleClick}>
                    <QuestionCircleOutlined style={{ fontSize: theme.typography.pxToRem(22) }} />
                  </IconAnchor>
                </IconWrap>
                <PopupMenu
                  anchorEl={anchorEl}
                  closeOnClick
                  closeOnClickOutside
                  dataTestId="app-header__list"
                  isOpen={open}
                  onClose={() => setAnchorEl(null)}
                  placement="bottom-end"
                >
                  <List index={-1}>
                    <ListItem dataTestId="app-header__list--support" onClick={() => openNewTab(linkT('supportPage'))}>
                      {prefixNS('contactSupport')}
                    </ListItem>
                    <ListItem dataTestId="app-header__list--help" onClick={() => openNewTab(linkT('helpDocs'))}>
                      {prefixNS('helpCenter')}
                    </ListItem>
                    <ListItem data-trackid="studio-feedback-trigger">{prefixNS('giveFeedback')}</ListItem>
                    <ListItem dataTestId="app-header__list--status" onClick={() => openNewTab(linkT('statusPage'))}>
                      {commonT('status')}
                    </ListItem>
                    <ListItem data-trackid="studio-tutorial-trigger">{prefixNS('guides')}</ListItem>
                    <ListItem
                      colorVariant="secondary"
                      css={css`
                        cursor: text;
                      `}
                      dataTestId="app-header__list--version"
                      onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                        copy(`${import.meta.env.VITE_APP_VERSION}`);
                        e.nativeEvent.stopImmediatePropagation();
                      }}
                    >
                      {import.meta.env.VITE_APP_VERSION}
                    </ListItem>
                  </List>
                </PopupMenu>
                {/* <IconWrap isLastOnRow>
              <NotificationBell notifications={[]} />
            </IconWrap> */}
                <ProfilePopup shouldCloseProfilePopup={open} />
              </Inline>
            </Inline>
          </HideOnTablet>
        </Inline>
        {isResiAdmin && (
          <Draft.ModalProvider>
            <AdminActionMenu />
          </Draft.ModalProvider>
        )}
      </Header>
    </Stack>
  );
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
