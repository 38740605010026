import { HTTP_METHODS } from '@studio/constants/axios';
import { CENTRAL_API_V3 } from '@studio/constants/env-variables';
import type { Client } from '@studio/types';

export const root = {
  [HTTP_METHODS.GET]: {
    url: `${CENTRAL_API_V3}/customers/@customerId/clients/roles`,
    response: [] as Client.Get.Role[],
    method: HTTP_METHODS.GET,
  },
};
