import * as React from 'react';
import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import type { Immutable } from 'immer';
import { produce } from 'immer';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { client } from '@studio/api/api-client/client';
import { isAxiosError } from '@studio/api/api-client/is-axios-error';
import { selectAccessToken, selectCustomerId } from '@studio/store';
import type { HttpMethods, ApiClientConfig } from '@studio/types/axios';

const DEFAULT_TIME_DELAY = 60_000;

const initialState = {
  hasFetched: false,
  isFetching: false,
  isFetchingInterval: false,
  isFetchingMore: false,
  error: null,
  data: undefined,
  mutatedData: undefined,
  responseHeaders: undefined,
};

type _State<T, M> = {
  data?: T;
  error: AxiosError | Error | null;
  hasFetched: boolean;
  isFetching: boolean;
  isFetchingInterval: boolean;
  isFetchingMore: boolean;
  mutatedData?: M;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseHeaders?: any;
  status?: number;
};

type _Actions<T, M> =
  | {
      payload: AxiosError | Error | null;
      type: 'FETCH_ERROR';
    }
  | {
      payload: AxiosResponse<T> & { mutatedData?: M };
      type: 'FETCHED_MORE';
    }
  | {
      payload: AxiosResponse<T> & { mutatedData?: M };
      type: 'FETCHED';
    }
  | {
      payload: T;
      type: 'SET_DATA';
    }
  | {
      payload?: boolean;
      type: 'FETCHING';
    }
  | {
      type: 'CANCEL_INTERVAL';
    }
  | {
      type: 'CLEAR_DATA';
    }
  | {
      type: 'FETCHING_INTERVAL';
    }
  | {
      type: 'FETCHING_MORE';
    };

type _CustomerIdOnly = { customerId?: string };

/**
 * Same as Nullable except without `null`.
 */
type _Optional<T> = T | undefined;

/**
 * Types that can be used to index native JavaScript types, (Object, Array, etc.).
 */
type _IndexSignature = number | string | symbol;

/**
 * An object of any index-able type to avoid conflicts between `{}`, `Record`, `object`, etc.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
type _Obj<O extends Record<_IndexSignature, unknown> | object = Record<_IndexSignature, unknown> | object> = Omit<
  O,
  never
> & {
  [K in keyof O as K extends never ? never : K]: K extends never ? never : O[K] extends never ? never : O[K];
};
/**
 * Any type that is indexable using `string`, `number`, or `symbol`.
 *
 * Serves as a companion to {@link OwnKeys} while maintaining the generalizable usage of {@link Obj}.
 */
type _Indexable<ValueTypes = unknown> =
  | _Obj
  | {
      [K: _IndexSignature]: ValueTypes;
    };
/**
 * Picks only the _optional properties from a type, removing the required ones.
 * _Optionally, recurses through nested objects if `DEEP` is true.
 */
type _PickOptional<T, DEEP extends boolean = false> = {
  // `DEEP` must be false b/c `never` interferes with root level objects with both optional/required properties
  // If `undefined` extends the type of the value, it's optional (e.g. `undefined extends string | undefined`)
  [K in keyof T as undefined extends T[K] ? K : never]: DEEP extends false
    ? T[K]
    : T[K] extends _Optional<_Indexable> // Like above, we must include `undefined` so we can recurse through both nested keys in `{ myKey?: { optionalKey?: object, requiredKey: object }}`
      ? _PickOptional<T[K], DEEP>
      : T[K];
};

type _PickRequired<T, DEEP extends boolean = false> = {
  [K in keyof T as K extends keyof _PickOptional<T, DEEP> ? never : K]: T[K] extends _Indexable
    ? _PickRequired<T[K], DEEP>
    : T[K];
};

type _Params<T> =
  T extends _PickRequired<T>
    ? { params: Required<Omit<T, 'customerId'>> & { customerId?: string } }
    : { params?: _CustomerIdOnly };

type _Query<T> = T extends _PickRequired<T> ? { query: T } : { query?: T };

type _CallAPI<D, P, Q, R, H, T extends boolean = false> = (
  args?: D,
  optionalParams?: { clearData?: boolean; headers?: H; params?: Partial<P>; query?: Q; silent?: boolean }
) => T extends true ? Promise<AxiosResponse<R>> : Promise<R>;
type _CallAPIInterval<D, P, Q, R, H, T extends boolean = false> = (
  args?: D,
  optionalParams?: { clearData?: boolean; headers?: H; interval?: number; params?: Partial<P>; query?: Q }
) => T extends true ? Promise<AxiosResponse<R> | undefined> : Promise<R | undefined>;

type _MethodReturns<D, P, Q, R, H, T extends boolean = false> = {
  callApi: _CallAPI<D, P, Q, R, H, T>;
  callInterval: _CallAPIInterval<D, P, Q, R, H, T>;
};

type _HeadersMethod<D, P, Q, R, H, T extends boolean = false> = {
  headers: (
    headers: H
  ) => _MethodReturns<D, P, Q, R, H, T> & _ParamsMethod<D, P, Q, R, H, T> & _QueryMethod<D, P, Q, R, H, T>;
};
type _IntervalMethod<D, P, Q, R, H, T extends boolean = false> = {
  interval: (
    ms: number
  ) => _HeadersMethod<D, P, Q, R, H, T> &
    _MethodReturns<D, P, Q, R, H, T> &
    _ParamsMethod<D, P, Q, R, H, T> &
    _QueryMethod<D, P, Q, R, H, T>;
};
type _QueryMethod<D, P, Q, R, H, T extends boolean = false> = {
  query: (
    queryParams: Q
  ) => _HeadersMethod<D, P, Q, R, H, T> &
    _IntervalMethod<D, P, Q, R, H, T> &
    _MethodReturns<D, P, Q, R, H, T> &
    _ParamsMethod<D, P, Q, R, H, T>;
};
type _ParamsMethod<D, P, Q, R, H, T extends boolean = false> = {
  params: (
    pathParams: Partial<P>
  ) => _HeadersMethod<D, P, Q, R, H, T> &
    _IntervalMethod<D, P, Q, R, H, T> &
    _MethodReturns<D, P, Q, R, H, T> &
    _QueryMethod<D, P, Q, R, H, T>;
};

export type _ReturnType<D, M, P, Q, R, H, T extends boolean = false> = _HeadersMethod<D, P, Q, R, H, T> &
  _IntervalMethod<D, P, Q, R, H, T> &
  _MethodReturns<D, P, Q, R, H, T> &
  _ParamsMethod<D, P, Q, R, H, T> &
  _QueryMethod<D, P, Q, R, H, T> &
  Immutable<_State<R, M>> & {
    callNextPage: () => Promise<R | undefined>;
    cancelInterval: () => void;
    pageData: Map<string, R>;
    pageUrl?: string;
    setData: (data: R) => void;
    timeoutId: ReturnType<typeof setTimeout> | null;
  };

function createReducer<T, M>(state: _State<T, M>, action: _Actions<T, M>) {
  return produce(state, (draft: _State<T, M>) => {
    switch (action.type) {
      case 'FETCHING':
        draft.isFetchingInterval = Boolean(action.payload);
        draft.isFetching = true;
        draft.error = null;
        break;
      case 'FETCHING_MORE':
        draft.isFetchingMore = true;
        draft.error = null;
        break;
      case 'CLEAR_DATA':
        draft.data = undefined;
        draft.mutatedData = undefined;
        draft.responseHeaders = undefined;
        draft.error = null;
        draft.status = undefined;
        break;
      case 'CANCEL_INTERVAL':
        draft.isFetchingInterval = false;
        break;
      case 'FETCHING_INTERVAL':
        draft.error = null;
        draft.isFetchingInterval = true;
        break;
      case 'FETCHED':
        draft.data = action.payload.data;
        draft.mutatedData = action.payload.mutatedData;
        draft.responseHeaders = action.payload.headers;
        draft.status = action.payload.status;
        draft.error = null;
        draft.isFetching = false;
        if (!draft.hasFetched) {
          draft.hasFetched = true;
        }
        break;
      case 'FETCHED_MORE':
        draft.data = action.payload.data;
        draft.mutatedData = action.payload.mutatedData;
        draft.responseHeaders = action.payload.headers;
        draft.error = null;
        draft.status = action.payload.status;
        draft.isFetchingMore = false;
        break;
      case 'SET_DATA':
        draft.data = action.payload;
        break;
      case 'FETCH_ERROR':
        draft.error = action.payload;
        if (isAxiosError(action.payload)) {
          draft.status = action.payload.response?.status;
        }
        draft.isFetching = false;
        draft.isFetchingMore = false;
        break;
      /* istanbul ignore next */
      default:
        break;
    }
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const paramsSerializer = (paramsToSerialize: any, paramOptions?: qs.IStringifyOptions): string =>
  qs.stringify(paramsToSerialize, paramOptions ?? { encode: false });

export default function useClient<D, M, R, P, Q, H, T extends boolean = false>({
  config,
  dataPrep,
  headers: headersProp,
  interval: intervalProp,
  mutateData,
  params: paramsProp,
  parsePageUrl,
  query: queryProp,
  returnAxiosResponse,
  token,
  ...otherAxiosApiClientConfig
}: _Params<P> &
  _Query<Q> &
  ApiClientConfig & {
    config: {
      headers?: H;
      method: HttpMethods;
      paramOptions?: qs.IStringifyOptions;
      params?: P;
      query?: Q;
      request?: D;
      response?: R;
      url: string;
    };
    dataPrep?: (d: R) => R;
    headers?: H;
    interval?: number;
    mutateData?: (data: R, previousPageData?: R[]) => M;
    parsePageUrl?: (response: R, headers: AxiosResponse<R>['headers']) => string | undefined;
    returnAxiosResponse?: T;
    token?: string;
  }): _ReturnType<D, M, P, Q, R, H, T> {
  const { method, paramOptions, query, url } = config;
  const otherAxiosConfigRef = React.useRef(otherAxiosApiClientConfig);
  const timeoutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const mounted = React.useRef(false);
  const authToken = useSelector(selectAccessToken) ?? '';
  const customerId = useSelector(selectCustomerId) ?? '';
  const pageData = React.useRef(new Map<string, R>());
  const pageUrl = React.useRef<string | undefined>();
  const queryParams = React.useRef(queryProp ?? query);
  const pathParams = React.useRef(paramsProp);
  const reqHeaders = React.useRef(headersProp);
  const intervalRef = React.useRef(intervalProp || DEFAULT_TIME_DELAY);

  const newUrl = React.useCallback(
    (p?: Partial<P>) => {
      const paramsToProcess = {
        customerId,
        ...(pathParams.current ?? {}),
        ...(p ?? {}),
      };

      return Object.keys(paramsToProcess).reduce((agg, paramToProcess) => {
        const regex = new RegExp(`@${paramToProcess}`, 'g');
        return agg.replace(regex, paramsToProcess[paramToProcess as keyof typeof paramsToProcess]);
      }, url);
    },
    [customerId, pathParams, url]
  );

  const [state, dispatch] = React.useReducer(createReducer<R, M>, initialState);

  const getSharedAxiosConfig = React.useCallback(
    () => ({
      method,
      token: token ?? authToken,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      paramsSerializer: (params: any) => paramsSerializer(params, paramOptions),
      returnResponse: true,
      ...otherAxiosConfigRef.current,
    }),
    [authToken, method, paramOptions, token]
  );

  const callNextPage = React.useCallback(async () => {
    if (pageUrl.current) {
      if (mounted.current) {
        dispatch({ type: 'FETCHING_MORE' });
      }

      try {
        const response = await client<AxiosResponse<R>>(pageUrl.current, {
          headers: reqHeaders.current,
          ...getSharedAxiosConfig(),
        });

        const dataToDispatch = dataPrep ? dataPrep(response.data) : response.data;

        if (mounted.current) {
          dispatch({
            type: 'FETCHED_MORE',
            payload: {
              ...response,
              data: dataToDispatch,
              // provide data in chronological order
              mutatedData: mutateData?.(dataToDispatch, Array.from(pageData.current.values()).reverse()),
            },
          });
          pageData.current.set(pageUrl.current, dataToDispatch);
          pageUrl.current = parsePageUrl?.(response.data, response.headers);
        }

        if (returnAxiosResponse) {
          return { ...response, data: dataToDispatch };
        } else {
          return dataToDispatch;
        }
      } catch (err) {
        if (mounted.current && err instanceof Error) {
          dispatch({ type: 'FETCH_ERROR', payload: err });
        }
        throw err;
      }
    }
  }, [getSharedAxiosConfig, dataPrep, returnAxiosResponse, mutateData, parsePageUrl]);

  const callApi = React.useCallback(
    async (
      payload?: D,
      optionalParams?: { clearData?: boolean; headers?: H; params?: Partial<P>; query?: Q; silent?: boolean }
    ) => {
      !optionalParams?.silent && dispatch({ type: 'FETCHING', payload: Boolean(timeoutId.current) });
      if (optionalParams?.clearData) {
        pageUrl.current = undefined;
        pageData.current.clear();
        dispatch({ type: 'CLEAR_DATA' });
      }

      try {
        const response = await client<AxiosResponse<R>>(newUrl(optionalParams?.params), {
          data: payload,
          headers: { ...reqHeaders.current, ...optionalParams?.headers },
          params: { ...queryParams.current, ...optionalParams?.query },
          ...getSharedAxiosConfig(),
        });

        const dataToDispatch = dataPrep ? dataPrep(response.data) : response.data;

        if (mounted.current) {
          dispatch({
            type: 'FETCHED',
            payload: {
              ...response,
              data: dataToDispatch,
              mutatedData: mutateData?.(
                dataToDispatch,
                // provide data in chronological order
                optionalParams?.clearData ? [] : Array.from(pageData.current.values()).reverse()
              ),
            },
          });
          pageUrl.current = parsePageUrl?.(response.data, response.headers);
          pageData.current.set(axios.getUri(response.config), dataToDispatch);
        }

        if (returnAxiosResponse) {
          return { ...response, data: dataToDispatch };
        } else {
          return dataToDispatch;
        }
      } catch (err) {
        if (mounted.current && err instanceof Error) {
          dispatch({ type: 'FETCH_ERROR', payload: err });
        }
        throw err;
      }
    },
    [newUrl, getSharedAxiosConfig, dataPrep, returnAxiosResponse, mutateData, parsePageUrl]
  );

  const callInterval = React.useCallback(
    async (
      payload?: D,
      optionalParams?: { clearData?: boolean; headers?: H; interval?: number; params?: Partial<P>; query?: Q }
    ) => {
      const tick = async () => {
        if (timeoutId.current) {
          dispatch({ type: 'FETCHING_INTERVAL' });
        } else {
          dispatch({ type: 'FETCHING', payload: true });
        }

        if (optionalParams?.clearData) {
          pageUrl.current = undefined;
          pageData.current.clear();
          dispatch({ type: 'CLEAR_DATA' });
        }
        try {
          const response = await client<AxiosResponse<R>>(newUrl(optionalParams?.params), {
            data: payload,
            headers: { ...reqHeaders.current, ...optionalParams?.headers },
            params: { ...queryParams.current, ...optionalParams?.query },
            ...getSharedAxiosConfig(),
          });
          if (mounted.current) {
            timeoutId.current = setTimeout(tick, optionalParams?.interval || intervalRef.current);
            const dataToDispatch = dataPrep ? dataPrep(response.data) : response.data;
            dispatch({
              type: 'FETCHED',
              payload: {
                ...response,
                data: dataToDispatch,
                mutatedData: mutateData?.(
                  dataToDispatch,
                  optionalParams?.clearData ? [] : Array.from(pageData.current.values())
                ),
              },
            });

            if (returnAxiosResponse) {
              return { ...response, data: dataToDispatch };
            } else {
              return dataToDispatch;
            }
          }
        } catch (err) {
          if (mounted.current && err instanceof Error) {
            dispatch({ type: 'FETCH_ERROR', payload: err });
          }
        }
      };

      return tick();
    },
    [newUrl, getSharedAxiosConfig, dataPrep, mutateData, returnAxiosResponse]
  );

  const setData = React.useCallback((dataToSet: R) => {
    dispatch({ type: 'SET_DATA', payload: dataToSet });
  }, []);

  const setters = React.useMemo(
    () => ({
      headers: (h: H) => {
        reqHeaders.current = { ...reqHeaders.current, ...h };

        return { callApi, callInterval, query: setters.query, params: setters.params, interval: setters.interval };
      },
      interval: (i: number) => {
        intervalRef.current = i;

        return { callApi, callInterval, query: setters.query, params: setters.params, headers: setters.headers };
      },
      params: (p: Partial<P>) => {
        pathParams.current = p;

        return { callApi, callInterval, query: setters.query, interval: setters.interval, headers: setters.headers };
      },
      query: (q: Q) => {
        queryParams.current = q;

        return { callApi, callInterval, params: setters.params, interval: setters.interval, headers: setters.headers };
      },
    }),
    [callApi, callInterval]
  );

  const cancelInterval = React.useCallback(() => {
    if (timeoutId.current) {
      window.clearInterval(timeoutId.current);
      timeoutId.current = null;
      if (mounted.current) {
        dispatch({ type: 'CANCEL_INTERVAL' });
      }
    }
  }, []);

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
    return () => {
      cancelInterval();
      mounted.current = false;
    };
  }, [cancelInterval]);

  const values = React.useMemo(
    () => ({
      callApi,
      callInterval,
      callNextPage,
      cancelInterval,
      headers: setters.headers,
      interval: setters.interval,
      params: setters.params,
      query: setters.query,
      setData,
      timeoutId: timeoutId.current,
      pageData: pageData.current,
      pageUrl: pageUrl.current,
      ...state,
    }),
    [
      callApi,
      callInterval,
      callNextPage,
      cancelInterval,
      setData,
      setters.headers,
      setters.interval,
      setters.params,
      setters.query,
      state,
    ]
  );
  return values as _ReturnType<D, M, P, Q, R, H, T>;
}
