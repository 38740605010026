import {
  DesktopOutlined,
  LineChartOutlined,
  SettingOutlined,
  TeamOutlined,
  ClusterOutlined,
  ReconciliationOutlined,
  SearchOutlined,
  UploadOutlined,
  LockFilled,
  DashboardOutlined,
  CodeOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { AddVideo, Broadcast, Playlist, PlayFolder, PrismEncoder, ResiRay, SecurityShield } from '@resi-media/resi-ui';
import { MenuItems, MenuPermissions } from '@studio/constants/nav-constants';
import UrlPaths from '@studio/constants/url-paths';
import type { Library } from '@studio/types';
import type { SideMenuCategory, SideMenuConfig, SideMenuSection, SideMenuSubsection } from '@studio/types/navigation';
import { S } from './styles';

const adminCategories: SideMenuCategory[] = [
  {
    sections: [
      {
        label: MenuItems.ADMIN_SEARCH,
        icon: <SearchOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.ORGANIZATIONS],
        requiredPerms: MenuPermissions[MenuItems.ADMIN_SEARCH].permissions,
      },
    ],
  },
  {
    requiredPerms: MenuPermissions[MenuItems.CURRENT_ORG].permissions,
    sectionCategory: MenuItems.CURRENT_ORG,
    sections: [
      {
        end: true,
        label: MenuItems.OVERVIEW,
        icon: <ReconciliationOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.CURRENT_ORG],
        requiredPerms: MenuPermissions[MenuItems.CURRENT_ORG].permissions,
      },
      {
        label: MenuItems.SYSTEM_LOGS,
        icon: <UnorderedListOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.SYSTEM_LOGS],
        requiredPerms: MenuPermissions[MenuItems.SYSTEM_LOGS].permissions,
      },
      {
        label: MenuItems.ADMIN_ENCODERS,
        icon: <PrismEncoder className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.CURRENT_ORG_ENCODERS],
        requiredPerms: MenuPermissions[MenuItems.ADMIN_ENCODERS].permissions,
      },
    ],
  },
  {
    requiredPerms: MenuPermissions[MenuItems.HARDWARE].permissions,
    sectionCategory: MenuItems.HARDWARE,
    sections: [
      {
        end: true,
        label: MenuItems.HARDWARE_SEARCH,
        icon: <SearchOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.HARDWARE_SEARCH],
        requiredPerms: MenuPermissions[MenuItems.HARDWARE_SEARCH].permissions,
      },
      {
        label: MenuItems.ENCODER_SEARCH,
        icon: <SearchOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.ENCODER_SEARCH],
        requiredPerms: MenuPermissions[MenuItems.ENCODER_SEARCH].permissions,
      },
      {
        label: MenuItems.MONITORING,
        icon: <LineChartOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.HARDWARE_MONITORING],
        requiredPerms: MenuPermissions[MenuItems.MONITORING].permissions,
      },
      {
        label: MenuItems.HARDWARE_DASHBOARD,
        icon: <DashboardOutlined className="side-nav__icon" css={S.menuIcon} />,
        routes: [UrlPaths.ADMIN.HARDWARE_DASHBOARD],
        requiredPerms: MenuPermissions[MenuItems.HARDWARE_DASHBOARD].permissions,
      },
    ],
  },
];

export const ADMIN_MENU_CONFIG: SideMenuConfig = {
  categories: adminCategories,
};

export const getAnalyticsConfig = (hasLibraryTrialAccess: boolean): SideMenuConfig => {
  return {
    categories: [
      {
        sectionCategory: MenuItems.ANALYTICS,
        sections: [
          {
            end: true,
            label: MenuItems.EVENT_ANALYTICS,
            icon: <LineChartOutlined className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.ANALYTICS.ROOT],
            requiredPerms: MenuPermissions[MenuItems.ANALYTICS].permissions,
          },
          {
            dataTestId: hasLibraryTrialAccess
              ? 'library-analytics__menu-item--no-access'
              : 'library-analytics__menu-item',
            label: MenuItems.LIBRARY_ANALYTICS,
            icon: hasLibraryTrialAccess ? (
              <LockFilled className="side-nav__icon" />
            ) : (
              <PlayFolder className="side-nav__icon" css={S.menuIcon} />
            ),
            routes: [UrlPaths.ANALYTICS.MEDIA_LIBRARY],
            requiredPerms: MenuPermissions[MenuItems.LIBRARY_ANALYTICS].permissions,
          },
        ],
      },
    ],
  };
};

export const getLibraryConfig = (
  { isEnterprise, isPremium, isPro, vodPlanId }: Library.Derived.VodPlanType,
  showAdminView: boolean
): SideMenuConfig => {
  const statusSections: SideMenuSection[] = [
    {
      label: MenuItems.RECENTLY_UPLOADED,
      icon: <UploadOutlined className="side-nav__icon" css={S.menuIcon} />,
      routes: [UrlPaths.MEDIA.RECENTLY_UPLOADED],
      requiredPerms: MenuPermissions[MenuItems.RECENTLY_UPLOADED].permissions,
    },
  ];
  if (showAdminView) {
    statusSections.push({
      label: MenuItems.RECENT_RTMP,
      icon: <SecurityShield className="side-nav__icon" css={S.menuIcon} />,
      routes: [UrlPaths.MEDIA.RECENT_RTMP],
      requiredPerms: MenuPermissions[MenuItems.RECENT_RTMP].permissions,
    });
  }

  return {
    categories: [
      {
        sectionCategory: MenuItems.SAVED_MEDIA,
        sections: [
          {
            label: MenuItems.LIBRARY,
            icon: <PlayFolder className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.MEDIA.LIBRARY],
            requiredPerms: MenuPermissions[MenuItems.LIBRARY].permissions,
            isGated: !vodPlanId,
          },
          {
            label: MenuItems.PLAYLISTS,
            icon: <AddVideo className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.MEDIA.PLAYLISTS],
            requiredPerms: MenuPermissions[MenuItems.PLAYLISTS].permissions,
            isGated: !vodPlanId,
          },
          {
            label: MenuItems.SITES,
            icon: <Playlist className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.MEDIA.SITES],
            requiredPerms: MenuPermissions[MenuItems.SITES].permissions,
            requiredToggles: [],
            isGated: !isEnterprise && !isPremium && !isPro,
          },
        ],
      },
      {
        sectionCategory: MenuItems.EXPIRING_MEDIA,
        sections: [
          {
            label: MenuItems.WEB_VIDEOS,
            icon: <DesktopOutlined className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.MEDIA.EXPIRING_WEB_VIDEOS],
            requiredPerms: MenuPermissions[MenuItems.WEB_VIDEOS].permissions,
          },
          {
            label: MenuItems.ENCODER_VIDEOS,
            icon: <ResiRay className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.MEDIA.EXPIRING_ENCODER_VIDEOS],
            requiredPerms: MenuPermissions[MenuItems.ENCODER_VIDEOS].permissions,
          },
        ],
      },
      {
        sectionCategory: MenuItems.STATUS,
        sections: statusSections,
      },
    ],
  };
};

export const getSettingsConfig = ({
  hasApiAccess,
  hasMultiSitePlan,
  hasQrClickAccess,
  hasRtmpAccess,
  hasRtmpDiscoverability,
  qrClickOverlaysAvailable,
}: {
  hasApiAccess: boolean;
  hasMultiSitePlan: boolean;
  hasQrClickAccess: boolean;
  hasRtmpAccess: boolean;
  hasRtmpDiscoverability: boolean;
  qrClickOverlaysAvailable: boolean;
}): SideMenuConfig => {
  const deviceSettingsSubMenu: SideMenuSubsection[] = [
    {
      label: MenuItems.ENCODERS,
      routes: [UrlPaths.SETTINGS.ENCODERS],
      requiredPerms: MenuPermissions[MenuItems.ENCODERS].permissions,
      dataTestId: 'encoders-menu-item',
    },
    {
      label: MenuItems.ENCODER_PRESETS,
      routes: [UrlPaths.SETTINGS.ENCODER_PRESETS],
      requiredPerms: MenuPermissions[MenuItems.ENCODER_PRESETS].permissions,
      dataTestId: 'encoder-presets-menu-item',
    },
    {
      label: MenuItems.ENCODER_CHANNELS,
      routes: [UrlPaths.SETTINGS.ENCODER_CHANNELS],
      requiredPerms: MenuPermissions[MenuItems.ENCODER_CHANNELS].permissions,
      dataTestId: 'encoder-channels-menu-item',
    },
  ];
  if (hasMultiSitePlan) {
    deviceSettingsSubMenu.push({
      label: MenuItems.VENUES,
      routes: [UrlPaths.SETTINGS.VENUES],
      requiredPerms: MenuPermissions[MenuItems.VENUES].permissions,
      dataTestId: 'venues-menu-item',
    });
  }
  const webSettingsSubMenu: SideMenuSubsection[] = [
    {
      label: MenuItems.WEB_PRESETS,
      routes: [UrlPaths.SETTINGS.WEB_PRESETS],
      requiredPerms: MenuPermissions[MenuItems.WEB_PRESETS].permissions,
      dataTestId: 'web-presets-menu-item',
    },
    {
      label: MenuItems.WEB_CHANNELS,
      routes: [UrlPaths.SETTINGS.WEB_CHANNELS],
      requiredPerms: MenuPermissions[MenuItems.WEB_CHANNELS].permissions,
      dataTestId: 'web-channels-menu-item',
    },
    {
      label: MenuItems.SOCIAL_MEDIA,
      routes: [UrlPaths.SETTINGS.SOCIAL_MEDIA],
      requiredPerms: MenuPermissions[MenuItems.SOCIAL_MEDIA].permissions,
      dataTestId: 'social-media-menu-item',
    },
  ];
  if (hasRtmpAccess || hasRtmpDiscoverability) {
    webSettingsSubMenu.push({
      isGated: hasRtmpDiscoverability,
      label: MenuItems.RTMP,
      routes: [UrlPaths.SETTINGS.RTMP_DESTINATIONS],
      requiredPerms: MenuPermissions[MenuItems.RTMP].permissions,
      dataTestId: 'rtmp-menu-item',
    });
  }
  webSettingsSubMenu.push(
    {
      label: MenuItems.DESTINATION_GROUPS,
      routes: [UrlPaths.SETTINGS.DESTINATION_GROUPS],
      requiredPerms: MenuPermissions[MenuItems.DESTINATION_GROUPS].permissions,
      dataTestId: 'destination-groups-menu-item',
    },
    {
      label: MenuItems.PLAYER_PROFILES,
      routes: [UrlPaths.SETTINGS.PLAYER_PROFILES],
      requiredPerms: MenuPermissions[MenuItems.PLAYER_PROFILES].permissions,
      dataTestId: 'player-profiles-menu-item',
    }
  );

  if (hasQrClickAccess) {
    webSettingsSubMenu.push({
      isGated: !qrClickOverlaysAvailable,
      label: MenuItems.QR_CLICK_OVERLAYS,
      routes: [UrlPaths.SETTINGS.QR_CLICK_OVERLAYS],
      requiredPerms: [],
      dataTestId: 'qr-click-menu-item',
    });
  }

  return {
    categories: [
      {
        requiredPerms: MenuPermissions[MenuItems.BROADCAST_SETTINGS].permissions,
        sectionCategory: MenuItems.BROADCAST_SETTINGS,
        sections: [
          {
            label: MenuItems.WEB_SETTINGS,
            icon: <Broadcast className="side-nav__icon" css={S.menuIcon} />,
            requiredPerms: MenuPermissions[MenuItems.WEB_SETTINGS].permissions,
            subMenu: webSettingsSubMenu,
          },
          {
            label: MenuItems.DEVICE_SETTINGS,
            icon: <SettingOutlined className="side-nav__icon" css={S.menuIcon} />,
            requiredPerms: MenuPermissions[MenuItems.DEVICE_SETTINGS].permissions,
            subMenu: deviceSettingsSubMenu,
          },
        ],
      },
      {
        requiredPerms: [MenuItems.ACCOUNT_SETTINGS],
        sectionCategory: MenuItems.ACCOUNT_SETTINGS,
        sections: [
          {
            label: MenuItems.ORGANIZATION_DETAILS,
            icon: <ClusterOutlined className="side-nav__icon" css={S.menuIcon} />,
            routes: [
              UrlPaths.SETTINGS.ORGANIZATION_DETAILS,
              UrlPaths.SETTINGS.ORGANIZATION_DETAILS_BANDWIDTH_USAGE,
              UrlPaths.SETTINGS.ORGANIZATION_DETAILS_LIBRARY_SETTINGS,
            ],
            requiredPerms: MenuPermissions[MenuItems.ORGANIZATION_DETAILS].permissions,
          },
          {
            label: MenuItems.USERS,
            icon: <TeamOutlined className="side-nav__icon" css={S.menuIcon} />,
            routes: [UrlPaths.SETTINGS.USERS],
            requiredPerms: MenuPermissions[MenuItems.USERS].permissions,
          },
          ...(hasApiAccess
            ? [
                {
                  label: MenuItems.API_ACCESS,
                  icon: <CodeOutlined className="side-nav__icon" css={S.menuIcon} />,
                  routes: [UrlPaths.SETTINGS.API_ACCESS],
                  requiredPerms: MenuPermissions[MenuItems.API_ACCESS].permissions,
                  requiredToggles: [],
                },
              ]
            : []),
        ],
      },
    ],
  };
};
