import type { CombinedState } from 'redux';
import { createSelector } from 'reselect';
import type { WebChannels } from '@studio/types';
import type { RootState } from '../root-reducer';
import type { WebChannelsState } from './types';

export const selectWebChannelsState = (state: RootState): CombinedState<WebChannelsState> => state.webChannels;

export const selectFilter = createSelector(selectWebChannelsState, (state): string => state.filter);

export const selectItems = createSelector(selectWebChannelsState, (state): WebChannels.Get.WebChannel[] => state.items);

export const selectOptions = createSelector([selectItems], (items) => {
  return items.reduce<Map<string, WebChannels.Components.WebChannelsOptions>>(
    (agg, profile) => agg.set(profile.uuid, { label: profile.name, value: profile.uuid, data: profile }),
    new Map()
  );
});

export const selectPublicChannels = createSelector([selectItems], (items) => {
  return items.filter((item) => item.type === 'public');
});

export const selectPublicOptions = createSelector([selectPublicChannels], (items) => {
  return items.reduce<Map<string, WebChannels.Components.WebChannelsOptions>>(
    (agg, profile) => agg.set(profile.uuid, { label: profile.name, value: profile.uuid, data: profile }),
    new Map()
  );
});

export const filterWebChannels = createSelector(
  selectItems,
  selectFilter,
  (items, filter): WebChannels.Get.WebChannel[] => {
    return items.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
  }
);

export const selectTimestamp = createSelector(selectWebChannelsState, (state): number => state.timestamp);

export const hasQrClick = createSelector(
  selectWebChannelsState,
  (state) => state.isFetching || state.items.some((channel) => channel.detectQrCodes)
);
