const UrlPaths = {
  ACCEPT_INVITE: '/userinvites/:id/accept',
  ENCODER_LOGS: '/encoders/:uuid/logs',
  GO_LIVE: '/go-live',
  SIGNUP_PLANS: '/signup/plans',
  OPEN_SOURCE_INFO: '/opensource',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_TOKEN: '/reset-password/:token',
  NOT_AUTHORIZED: '/not-authorized',
  NOT_FOUND: '/not-found',
  ROOT: '/',
  INVITE: 'invite',
  CREATE: 'create',
  VIEW: ':uuid',
  VERIFY_EMAIL: '/verify-email/:token',
  EDIT: ':uuid/edit',
  UPDATE: ':uuid/update',
  // for redirect from old links
  LIBRARY: '/library',

  // broken into categories for navigation components to reference
  DASHBOARD: {
    ROOT: '/',
  },
  MEDIA: {
    ROOT: '/media',
    ADD_PLAYLIST: '/media/playlists/add',
    ADD_TO_PLAYLIST: '/media/playlists/add/:uuid/:count',
    EXPIRING_ENCODER_VIDEOS: '/media/encoder-videos',
    VIEW_EXPIRING_ENCODER_VIDEO: '/media/encoder-videos/:uuid',
    START_RTMP_STREAM: '/media/web-videos/start-rtmp-stream',
    EXPIRING_WEB_VIDEOS: '/media/web-videos',
    VIEW_EXPIRING_WEB_VIDEO: '/media/web-videos/:uuid',
    SAVE_MEDIA: '/media/save',
    SITES: '/media/sites',
    SITES_CREATE_PANE: '/media/sites/create',
    SITES_EDIT_PANE: '/media/sites/:uuid/edit',
    SITES_VIEW_PANE: '/media/sites/:uuid',
    UPLOAD_VIDEO: '/media/upload',
    VIEW_SAVED_MEDIA: '/media/library/:uuid',
    RECENT_RTMP: '/media/recent-rtmp',
    RECENTLY_UPLOADED: '/media/recently-uploaded',
    START_WEB_VIDEO: '/media/start-web-video/:uuid',
    TRIM_LIBRARY_VIDEO: '/media/library/:uuid/trim',
    PLAYLISTS: '/media/playlists',
    VIEW_PLAYLIST: '/media/playlists/:uuid',
    LIBRARY: '/media/library',
  },
  UPLOADER: {
    ROOT: '/uploader',
    RESET_PASSWORD: '/uploader/reset-password',
    SUCCESS: '/uploader/success',
  },
  UNREGISTER: {
    ROOT: '/unregisteruploader',
    SUCCESS: '/unregisteruploader/success',
  },
  SCHEDULE: {
    ROOT: '/schedule',
    EVENT_CREATE: '/schedule/create',
    EVENT_VIEW: '/schedule/event/:uuid',
    EVENT_EDIT: '/schedule/event/:uuid/edit',
    ENCODER_SCHEDULE_VIEW: '/schedule/encoder/:uuid',
    ENCODER_SCHEDULE_EDIT: '/schedule/encoder/:uuid/edit',
  },
  ADMIN: {
    ROOT: '/admin',
    CURRENT_ORG: '/admin/current-org',
    CURRENT_ORG_EDIT: '/admin/current-org/:uuid/edit',
    CURRENT_ORG_ENCODER: '/admin/current-org/encoder',
    CURRENT_ORG_ENCODER_UPDATE: '/admin/current-org/encoder/:uuid/update',
    CURRENT_ORG_ENCODERS_EDIT: '/admin/current-org/encoders/:uuid/edit',
    CURRENT_ORG_ENCODERS_VIEW: '/admin/current-org/encoders/:uuid',
    CURRENT_ORG_ENCODERS: '/admin/current-org/encoders',
    ENCODER_SEARCH: '/admin/current-org/encoder-search',
    HARDWARE_ADD: '/admin/current-org/hardware/create',
    HARDWARE_EDIT: '/admin/current-org/hardware/:uuid/edit',
    HARDWARE_VIEW: '/admin/current-org/hardware/:uuid',
    HARDWARE_SEARCH: '/admin/current-org/hardware',
    HARDWARE_DASHBOARD: '/admin/current-org/hardware-dashboard',
    HARDWARE_MONITORING: '/admin/current-org/monitoring',
    SYSTEM_LOGS: '/admin/current-org/system-logs',
    ORGANIZATIONS: '/admin/organizations',
    ORGANIZATIONS_VIEW: '/admin/organizations/:uuid',
    ORGANIZATIONS_ADD: '/admin/organizations/create',
    ORGANIZATIONS_EDIT: '/admin/organizations/:uuid/edit',
  },
  ANALYTICS: {
    ROOT: '/analytics',
    MEDIA_LIBRARY: '/analytics/media-library',
  },
  SETTINGS: {
    ROOT: '/settings',
    ACCOUNT: '/settings/account',
    API_ACCESS: '/settings/api-access',
    API_ACCESS_CREATE_PANE: '/settings/api-access/create',
    API_ACCESS_EDIT_PANE: '/settings/api-access/:uuid/edit',
    API_ACCESS_VIEW_PANE: '/settings/api-access/:uuid',
    DESTINATION_GROUPS: '/settings/destination-groups',
    DESTINATION_GROUPS_CREATE_PANE: '/settings/destination-groups/create',
    DESTINATION_GROUPS_EDIT_PANE: '/settings/destination-groups/:uuid/edit',
    DESTINATION_GROUPS_VIEW_PANE: '/settings/destination-groups/:uuid',
    ENCODER_CHANNELS: '/settings/encoder-channels',
    ENCODER_CHANNELS_CREATE: '/settings/encoder-channels/create',
    ENCODER_CHANNELS_EDIT_PANE: '/settings/encoder-channels/:uuid/edit',
    ENCODER_CHANNELS_VIEW_PANE: '/settings/encoder-channels/:uuid',
    ENCODERS: '/settings/encoders',
    ENCODERS_EDIT_PANE: '/settings/encoders/:uuid/edit',
    ENCODER_UPDATES: '/settings/encoders/:uuid/update',
    ENCODERS_VIEW_PANE: '/settings/encoders/:uuid',
    ENCODER_PRESETS: '/settings/encoder-presets',
    ENCODER_PRESETS_CREATE_PANE: '/settings/encoder-presets/create',
    ENCODER_PRESETS_CREATE_PANE_V2: '/settings/encoder-presets/create?isV2=true',
    ENCODER_PRESETS_EDIT_PANE: '/settings/encoder-presets/:uuid/edit',
    ENCODER_PRESETS_VIEW_PANE: '/settings/encoder-presets/:uuid',
    ORGANIZATION_DETAILS: '/settings/organization',
    ORGANIZATION_DETAILS_BANDWIDTH_USAGE: '/settings/organization/bandwidth-usage',
    ORGANIZATION_DETAILS_BANDWIDTH_USAGE_DAY: '/settings/organization/bandwidth-usage/day',
    ORGANIZATION_DETAILS_LIBRARY_SETTINGS: '/settings/organization/library-settings',
    PLAYER_PROFILES: '/settings/player-profiles',
    PLAYER_PROFILES_CREATE_PANE: '/settings/player-profiles/create',
    PLAYER_PROFILES_EDIT_PANE: '/settings/player-profiles/:uuid/edit',
    PLAYER_PROFILES_VIEW_PANE: '/settings/player-profiles/:uuid',
    QR_CLICK_OVERLAYS: '/settings/qr-click-overlays',
    QR_CLICK_OVERLAYS_CREATE: '/settings/qr-click-overlays/create',
    QR_CLICK_OVERLAYS_EDIT: '/settings/qr-click-overlays/:uuid/edit',
    QR_CLICK_OVERLAYS_VIEW: '/settings/qr-click-overlays/:uuid',
    RTMP_DESTINATIONS: '/settings/rtmp-destinations',
    RTMP_DESTINATIONS_CREATE: '/settings/rtmp-destinations/create',
    RTMP_DESTINATIONS_EDIT_PANE: '/settings/rtmp-destinations/:uuid/edit',
    RTMP_DESTINATIONS_VIEW_PANE: '/settings/rtmp-destinations/:uuid',
    SOCIAL_MEDIA: '/settings/social-media',
    SOCIAL_MEDIA_CREATE_PANE: '/settings/social-media/create',
    SOCIAL_MEDIA_EDIT_PANE: '/settings/social-media/:uuid/edit',
    SOCIAL_MEDIA_OAUTH_FB_REDIRECT: '/settings/social-media/fb-redirect',
    SOCIAL_MEDIA_VIEW_PANE: '/settings/social-media/:uuid',
    USERS: '/settings/users',
    USERS_EDIT_PANE: '/settings/users/:uuid/edit',
    USERS_INVITE: '/settings/users/invite',
    USERS_VIEW_PANE: '/settings/users/:uuid',
    VENUES: '/settings/venues',
    VENUES_CREATE_PANE: '/settings/venues/create',
    VENUES_EDIT_PANE: '/settings/venues/:uuid/edit',
    VENUES_VIEW_PANE: '/settings/venues/:uuid',
    //TODO: Will re-enable at a later date, currently a WIP
    // ACTIVATE_ENCODER: '/settings/encoders/activate',
    WEB_CHANNELS: '/settings/web-channels',
    WEB_CHANNELS_CREATE_PANE: '/settings/web-channels/create',
    WEB_CHANNELS_EDIT_PANE: '/settings/web-channels/:uuid/edit',
    WEB_CHANNELS_VIEW_PANE: '/settings/web-channels/:uuid',
    WEB_PRESETS: '/settings/web-presets',
    WEB_PRESETS_CREATE_PANE: '/settings/web-presets/create',
    WEB_PRESETS_EDIT_PANE: '/settings/web-presets/:uuid/edit',
    WEB_PRESETS_VIEW_PANE: '/settings/web-presets/:uuid',
  },
  HELP: {
    ROOT: '/help',
  },
} as const;

export default UrlPaths;
